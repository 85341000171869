import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


type NotifficationState = {
    message: string
    isOpen: boolean
    timeout: number
    buttons: []
}

const initialState: NotifficationState = {
    message: "",
    isOpen: false,
    timeout: 3000,
    buttons: []
}

export const slice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        showNotification: (state, action) => {
            state.isOpen = true
            state.message =  action.payload.message
            state.timeout = (action.payload?.timeout === null ? null : 3000)
            state.buttons = (action.payload?.buttons ? action.payload?.buttons : [])
        },
        resetNotification: (state) => {
            state.isOpen = false
            state.message =  ""
            state.timeout = 0
            state.buttons = []
        }
    },
    extraReducers: (builder) => {
    }
})

export const {
    showNotification,
    resetNotification
} = slice.actions
export default slice.reducer
