import styled from "@emotion/styled"

type WrapperProps = {
  height?: number | string
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colors.header.background};
  /* flex-direction: column; */
`

export default Wrapper
