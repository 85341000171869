import React, {useEffect} from "react"
import styled from "@emotion/styled"
import {useSelector} from "react-redux";
import { usStates } from "../../constants"
import Button from "../CommonStyles/Button"
import InputsContainer from "../CommonStyles/InputsContainer"
import Label from "../CommonStyles/Label"
import Text from "../CommonStyles/Text";
import {Link} from "react-router-dom";


type ContainerProps = {
  borderBottom?: string
}

type RightContainerProps = {
  justifyContent?: string
}

const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 10px;
`
const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: ${(props: ContainerProps) =>
    props.borderBottom || "2px solid #dcdcdc"};
  height: 50px;
`
const RightContainer = styled.div`
  display: flex;
  margin-left: auto;
  width: 136px;
  justify-content: ${(props: RightContainerProps) =>
    props.justifyContent || "space-between"};
`

const RightItemContainer = styled.div`
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
`
const FlexRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const Select = styled("select")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  ::placeholder {
    font-size: 14px;
    padding-left: 4px;
  }
`
const Option = styled("option")`
  box-sizing: border-box;
  background-color: #0000000d;
  outline: none;
  border: none;
  ::placeholder {
    font-size: 14px;
    padding-left: 4px;
  }
`

const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const StateInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 10px;
  padding: 10px;
`

const StateContainer = styled.div`
  box-sizing: border-box;
  height: 60px;
  width: 90%;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
`

const StepThree: React.FC<{
  formFields: {
      addressOne: string
      addressTwo: string
      city: string
      state: string
  }
    createChangeHandler: any
    goToPreviousView: any
    handleFinish: any
}> = (props) => {
    const eventData = useSelector((state: any) => state.event)

    useEffect(() => {
        const elementScroll = document.getElementById("scrollBody")
        if (elementScroll) {
            elementScroll.scroll(0, 0)
        }
    }, [])

  return (
    <>
        <Text textAlign={"center"} important fontSize="20px">
            (Optional)
        </Text>
        <InputsContainer>
            <Label htmlFor="address1">Address Line 1</Label>
            <Input
                type="text"
                id="address1"
                placeholder="Address Line 1"
                name="address1"
                value={props.formFields.addressOne}
                onChange={props.createChangeHandler("addressOne")}
            ></Input>
        </InputsContainer>
        <InputsContainer>
            <Label htmlFor="address2">Address Line 2</Label>
            <Input
                type="text"
                id="address2"
                placeholder="Address Line 2"
                name="address2"
                value={props.formFields.addressTwo}
                onChange={props.createChangeHandler("addressTwo")}
            ></Input>
        </InputsContainer>
        <InputsContainer>
            <Label htmlFor="cellPhone">City</Label>
            <Input
                type="text"
                id="city"
                placeholder="Enter your City"
                name="city"
                value={props.formFields.city}
                onChange={props.createChangeHandler("city")}
            ></Input>
        </InputsContainer>

        <StateInputsContainer>
            <Label htmlFor="state">State</Label>
            <Select
                value={props.formFields.state}
                onChange={props.createChangeHandler("state")}
            >
                {usStates.map((state, index) => {
                    return (
                        <Option value={state.abbreviation} key={index}>
                            {state.abbreviation}
                        </Option>
                    )
                })}
            </Select>
        </StateInputsContainer>

      <FlexRow>
        <Button
          height="40px"
          width="200px"
          backgroundColor="white"
          color="black"
          style={{ zIndex: 1 }}
          onClick={props.goToPreviousView}
        >
          Back
        </Button>
        <Button style={{ zIndex: 1 }} height="40px" width="200px" onClick={props.handleFinish}>
          Finish
        </Button>
      </FlexRow>
        <FlexRow>
            <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
                <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
                    AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
                    <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventData.eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
                </span>
                </div>
            </div>
        </FlexRow>
    </>
  )
}

export default StepThree
