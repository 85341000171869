import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";

import {
  fetchAuctionItemsData,
  sortOptions,
  updateAuctionItems,
  useAuctionItemsState,
  useSortValue,
} from "../../redux/slices/auctionItemsSlice";
import { AUCTION_ITEMS_ENDPOINT, getEndPoint } from "../../util/api/enpoints";
import { filtersConfig } from "./SlideOutMenu";

type CheckboxAction =
  | { type: "filters"; payload: { name: string; value: boolean } }
  | { type: "tags"; payload: { name: string; value: boolean } };

type CheckboxState = {
  filters: string[];
  tags: string[];
};

const reducer = (state: CheckboxState, action: CheckboxAction) => {
  switch (action.type) {
    case "filters":
      if (action.payload.value === true) {
        const filters = state.filters.concat(action.payload.name);
        return {
          ...state,
          filters,
        };
      } else if (action.payload.value === false) {
        const filters = state.filters.filter(
          (filter: any) => filter !== action.payload.name
        );
        return {
          ...state,
          filters,
        };
      } else {
        return state;
      }
    case "tags":
      if (action.payload.value === true) {
        const tags = state.tags.concat(action.payload.name);
        return {
          ...state,
          tags,
        };
      } else if (action.payload.value === false) {
        const tags = state.tags.filter((tag) => tag !== action.payload.name);
        return {
          ...state,
          tags,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const useFiltersReducer = () => {
  const initialState: CheckboxState = {
    filters: [],
    tags: [],
  };

  return useReducer(reducer, initialState);
};

export const useFilterByTagsAndFilters = ({
  filtersState,
  setFilteredItems,
  searchedItems,
}) => {
  useEffect(() => {
    /**
     * case when both the filter arrays are empty
     * at that time display items simply will be auction items themselves
     */
    if (filtersState.tags.length === 0 && filtersState.filters.length <= 0) {
      setFilteredItems(searchedItems);
    }

    if (filtersState.filters.length > 0 && filtersState.tags.length <= 0) {
      let filteredList: any = [];
      filtersState.filters.forEach((filter) => {
        const filterConfig = filtersConfig.find((f) => f.param === filter);

        searchedItems.forEach((item: any) => {
          if (item[filter] === filterConfig.filteredValue) {
            const doesExist = filteredList.some(
              (filteredItem: any) => filteredItem.itemid === item.itemid
            );
            if (!doesExist) {
              filteredList.push(item);
            }
          }
        });
      });

      setFilteredItems(filteredList);
    }

    if (filtersState.tags.length > 0 && filtersState.filters.length <= 0) {
      let filteredTags: any = [];
      filtersState.tags.forEach((tag) => {
        searchedItems.forEach((item: any) => {
          if (item.tags.includes(tag)) {
            const doesExist = filteredTags.some(
              (filteredItem: any) => filteredItem.itemid === item.itemid
            );
            if (!doesExist) {
              filteredTags.push(item);
            }
          }
        });
      });
      setFilteredItems(filteredTags);
    }

    if (filtersState.filters.length > 0 && filtersState.tags.length > 0) {
      let filteredList: any = [];
      filtersState.filters.forEach((filter) => {
        const filterConfig = filtersConfig.find((f) => f.param === filter);
        searchedItems.forEach((item: any) => {
          if (item[filter] === filterConfig.filteredValue) {
            const doesExist = filteredList.some(
              (filteredItem: any) => filteredItem.itemid === item.itemid
            );
            if (!doesExist) {
              filteredList.push(item);
            }
          }
        });
      });
      let filteredTags: any = [];
      filtersState.tags.forEach((tag) => {
        searchedItems.forEach((item: any) => {
          if (item.tags.includes(tag)) {
            const doesExist = filteredTags.some(
              (filteredItem: any) => filteredItem.itemid === item.itemid
            );
            if (!doesExist) {
              filteredTags.push(item);
            }
          }
        });
      });

      let newList = filteredList.concat(filteredTags);
      const newFL = newList.filter(
        (item: any, index: any, self: any) =>
          index === self.findIndex((item1: any) => item1.itemid === item.itemid)
      );
      setFilteredItems(newFL);
    }
  }, [searchedItems, filtersState, setFilteredItems]);
};

export const useFilterBySearchTerm = ({
  searchString,
  auctionItems,
  setSearchedItems,
}) => {
  useEffect(() => {
    if (searchString) {
      const searchItems =
        auctionItems?.filter((item: any) =>
          item.name.toLowerCase().includes(searchString.toLowerCase())
        ) || [];
      setSearchedItems(searchItems);
    } else {
      setSearchedItems(auctionItems);
    }
  }, [searchString, auctionItems, setSearchedItems]);
};

export const useSortItems = ({ filteredItems, setSortedItems }) => {
  const sortValue = useSortValue();

  useEffect(() => {
    if (sortValue === sortOptions.ALPHA_ASC) {
      const alphaSortedItems = [...filteredItems].sort((itemA, itemB) => {
        const nameA = itemA.name.toUpperCase(); // ignore upper and lowercase
        const nameB = itemB.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setSortedItems(alphaSortedItems);
    }
    if (sortValue === sortOptions.FEATURED_ITEMS) {
      const alphaSortedItems = filteredItems;
      setSortedItems(alphaSortedItems);
    }
    if (sortValue === sortOptions.ALPHA_DESC) {
      const alphaSortedItems = [...filteredItems].sort((itemA, itemB) => {
        const nameA = itemA.name.toUpperCase(); // ignore upper and lowercase
        const nameB = itemB.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      setSortedItems(alphaSortedItems);
    }
    if (sortValue === sortOptions.ENDING_SOON) {
      const endingSoonSortedItems = [...filteredItems].sort((itemA, itemB) => {
        const timeA = new Date(itemA.closing);
        const timeB = new Date(itemB.closing);
        if (timeA.getTime() <= timeB.getTime()) {
          return -1;
        }
        return 1;
      });
      const alreadyClosedSorted = [...endingSoonSortedItems].sort(
        (itemA, itemB) => {
          // Make sure already closed items are at the end
          const biddingA = itemA.bidstatus;
          const biddingB = itemB.bidstatus;

          if (biddingA < biddingB) {
            return 1;
          }
          if (biddingA > biddingB) {
            return -1;
          }
          return 0;
        }
      );
      setSortedItems(alreadyClosedSorted);
    }
    if (sortValue === sortOptions.PRICE_ASC) {
      const priceSortedItems = [...filteredItems].sort((itemA, itemB) => {
        const priceA = itemA.lastbid;
        const priceB = itemB.lastbid;
        if (priceA < priceB) {
          return -1;
        }
        if (priceA > priceB) {
          return 1;
        }
        return 0;
      });
      setSortedItems(priceSortedItems);
    }
    if (sortValue === sortOptions.PRICE_DESC) {
      const priceSortedItems = [...filteredItems].sort((itemA, itemB) => {
        const priceA = itemA.lastbid;
        const priceB = itemB.lastbid;
        if (priceA < priceB) {
          return 1;
        }
        if (priceA > priceB) {
          return -1;
        }
        return 0;
      });
      setSortedItems(priceSortedItems);
    }
  }, [filteredItems, setSortedItems, sortValue]);
};

export const useFetchAuctionItems = () => {
  const { status: auctionItemsFetchState, auctionitems: auctionItems } =
    useAuctionItemsState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (auctionItemsFetchState === "idle") {
      dispatch(fetchAuctionItemsData(getEndPoint(AUCTION_ITEMS_ENDPOINT)));
    }
    if (auctionItemsFetchState === "succeeded") {
      dispatch(updateAuctionItems());
    }
  }, [dispatch, auctionItemsFetchState]);
};

export const useSavedListView = (setListView) => {
  useEffect(() => {
    const cardView = localStorage.getItem("cardView");
    setListView(cardView === "list" ? true : false);
  });
};
