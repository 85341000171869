import styled from "@emotion/styled"
import Button from "./Button"
import { mq } from "../../constants";

type ButtonItem = {
  width?: number | string
}

const ButtonItem = styled(Button)`
  margin: 0;
  padding: 0;
  ${mq[0]} {
    width: ${(props) => props.width || "100%"};
  }
  ${mq[1]} {
    width: ${(props) => props.width || "100%"};
  }
  ${mq[2]} {
    width: ${(props) => props.width || "80%"};
    margin-left: 10%;
  }
  ${mq[3]} {
    width: ${(props) => props.width || "30%"};
    margin-left: ${(props) => props.width ? "0%" : "35%"};
    }
`
export default ButtonItem
