import React from "react";

export const IconCard = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs></defs>
      <rect style={{ fill: "none" }} width="24" height="24" />
      <path d="M21.5,0H2.5A2.5,2.5,0,0,0,0,2.5v19A2.5,2.5,0,0,0,2.5,24h19A2.5,2.5,0,0,0,24,21.5V2.5A2.5,2.5,0,0,0,21.5,0ZM15,19H5V16H15Zm4-5.5H5v-3H19ZM19,8H5V5H19Z" />
    </svg>
  );
};
