import "./styles.css";
import { ribbon, createOutline } from "ionicons/icons";
import React, {useEffect, useState} from "react";

import {IonButton, IonCol, IonIcon} from "@ionic/react";

import { IconLosingBid } from "../../components/Icons/IconLosingBid";
import { IconWinningBidder } from "../../components/Icons/IconWinningBidder";
import { UserBidStatus } from "../AuctionItems/Index";
import Text from "../CommonStyles/Text";
import ReactTextTransition, { presets } from "react-text-transition";
import {
  HighBidderTextContainer,
  IconContainer,
  LosingBidderTextContainer,
  StatusTextContainer
} from "./styledComponents";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/store";
import {css, keyframes} from "@emotion/react";
import styled from "@emotion/styled";

export const CurrentBid = ({
    currentBid,
    status, quantity,
    numOfBids,
    minimumBidIncrease,
    isPriceless,
    fairMarketValue,
    onMaxBid,
    accountisautobid,
    accountautobidamount,
    accountautobidincrement
}: {
    currentBid: number;
    status: UserBidStatus;
    quantity: number;
    numOfBids: number;
    minimumBidIncrease: number;
    isPriceless: boolean;
    fairMarketValue: number;
    onMaxBid: any;
    accountisautobid: boolean;
    accountautobidamount: number;
    accountautobidincrement: number;
}) => {
    const [bidUpdate, setBidUpdate] = React.useState(false);
    const auctionItemDetailsState = useSelector(
        (state: AppState) => state.auctionItemDetails
    );

    useEffect(() => {
        setBidUpdate(true);
        setTimeout(() => {
            setBidUpdate(false);
        }, 1000);
    }, [auctionItemDetailsState.currentbid])

  const renderIcon = () => {
    if (status === "highBidder") {
      return (
        <div style={{ marginBottom: 4 }}>
          <IconWinningBidder scale={1.2} />
        </div>
      );
    }
    if (status === "losingBidder") {
      return <IconLosingBid width={30} height={30} />;
    }
    if (status === "winner") {
      return (
        <div>
          <IonIcon
            icon={ribbon}
            slot="icon-only"
            color="dark"
            style={{ width: "1.5rem", height: "1.5rem", color: "green" }}
          />
        </div>
      );
    }

    return null;
  };

  const renderText = () => {
      if (quantity > 0) {
          if (status === "highBidder") {
              return (
                  <>
                      <div className={bidUpdate ? "updated" : ""} >
                          <HighBidderTextContainer>
                              <Text fontWeight="800" fontSize="24px">
                                  ${currentBid}
                              </Text>
                          </HighBidderTextContainer>
                      </div>
                      <Text
                          fontWeight="300"
                          fontSize="12px"
                          color="#666666"
                          padding="0 0 0.5rem 0"
                      >
                          {numOfBids} Bids
                      </Text>
                  </>
              );
          }
          if (status === "losingBidder") {
              return (
                  <>
                      <div className={bidUpdate ? "updated" : ""} >
                          <LosingBidderTextContainer>
                              <Text fontWeight="800" fontSize="24px">
                                  ${currentBid}
                              </Text>
                          </LosingBidderTextContainer>
                      </div>
                      <Text
                          fontWeight="300"
                          fontSize="12px"
                          color="#666666"
                          padding="0 0 0.5rem 0"
                      >
                          {numOfBids} Bids
                      </Text>
                  </>
              );
          }

          return (
              <>
                  <div className={bidUpdate ? "updated" : ""} >
                      <Text fontWeight="800" fontSize="24px">
                          ${currentBid}
                      </Text>
                  </div>
                  <Text
                      fontWeight="300"
                      fontSize="12px"
                      color="#666666"
                      padding="0 0 0.5rem 0"
                  >
                      {numOfBids} Bids
                  </Text>
              </>
          );


      }
      else if (quantity === 0) {
          return (
              <Text fontWeight="800" fontSize="24px">
                  SOLD OUT
              </Text>
          );
      }
  };

  const statusTextMap = {
    highBidder: "Current High Bidder",
    losingBidder: "Current Low Bidder",
    winner: "Winner",
  };

  const statusText = statusTextMap[status];

  const checkAccountStatus = () => {
    return (accountisautobid && status !== "winner" && status === "highBidder")
  }

  return (
    <>
      <IonCol
        sizeXs={checkAccountStatus() ? '3.5' :  '3.5'} sizeSm={checkAccountStatus() ? '3.5' : '3.5'} sizeMd={checkAccountStatus() ? '2.5' : '3.5'} sizeLg={checkAccountStatus() ? '2' : '2'} sizeXl={checkAccountStatus() ? '1.5' : '1.5'}
        style={{
          padding: 0,
          display: "grid",
          alignItems: "start",
          alignContent: "start",
          justifyItems: "start",
          gridTemplateColumns: status === "default" ? "1fr" : "2rem 1fr",
          background: status === "winner" ? "#D1F9D9" : "transparent",
          border: status === "winner" ? "2px solid #1CD182" : null,
          // border: "2px solid #1CD182",
          borderRadius: status === "winner" ? 6 : 0,
        }}
      >
        <IconContainer status={status}>{renderIcon()}</IconContainer>
        <StatusTextContainer play={false}>
          {statusText && (
            <Text
              style={{
                textDecoration: "uppercase",
                fontSize: "12px",
                color: "#666666",
                paddingTop: status === "winner" ? "0.5rem" : "0.5rem",
              }}
            >
              {statusText}
            </Text>
          )}
          {renderText()}
        </StatusTextContainer>
      </IonCol>
      {checkAccountStatus() &&
        <IonCol
            sizeXs={'5.5'} sizeSm={'5.5'} sizeMd={'6.50'} sizeLg={'7'} sizeXl={'7.5'}
            style={{
                padding: 0,
                display: "grid",
                gridTemplateColumns: "1fr",
                alignItems: "center",
                justifyItems: "start",
            }}
        >
            <IonButton id="notification-button" ion-button fill="clear" onClick={onMaxBid}>
                <Text fontWeight="300" fontSize="16px">
                    Your max bid: ${accountautobidamount}
                    <IonIcon
                        icon={createOutline}
                        slot="icon-only"
                        color="dark"
                        style={{  width: "1.5rem", height: "1rem"}}
                    />
                </Text>
            </IonButton>
        </IonCol>
      }
      <IonCol
        sizeXs={checkAccountStatus() ? '3' :  '8.5'} sizeSm={checkAccountStatus() ? '3' : '8.5'} sizeMd={checkAccountStatus() ? '3' : '8.5'} sizeLg={checkAccountStatus() ? '3' : '10'} sizeXl={checkAccountStatus() ? '3' : '10.5'}
        style={{  display: "grid", alignContent: "end", paddingRight: "0.5rem" }}
      >
          {(isPriceless) && (
          <Text textAlign="end" fontWeight="800" fontSize="20px">
           "Priceless"
          </Text>
          )}
          {(!isPriceless && fairMarketValue > 0) && (
              <Text textAlign="end" fontWeight="800" fontSize="20px">
                  ${fairMarketValue}
              </Text>)}
          {(!isPriceless && fairMarketValue > 0) && (
              <Text textAlign="end" fontWeight="300" fontSize="12px" color="#666666">
                  Fair Market Value
              </Text>
          )}
          {(!isPriceless && fairMarketValue <= 0) && (
              <Text textAlign="end" fontWeight="800" fontSize="20px">
                  ${minimumBidIncrease}
              </Text>)}
          {(!isPriceless && fairMarketValue <= 0) && (
              <Text textAlign="end" fontWeight="300" fontSize="12px" color="#666666">
              Min Bid Increase
              </Text>
          )}
      </IonCol>
    </>

  );
}

