import React from "react"
import { Link } from "react-router-dom"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"
import {useParams} from "react-router-dom";

interface Params {
    eventcode: string
}
const ResetPasswordEmail: React.FC = () => {
    const { eventcode } = useParams()

  return (
    <PageBodyContainer alignItems="center" justifyContent="center">
      <Text textAlign="center" important fontWeight={600}>
          An email has been sent to your registered email to help you login.
      </Text>
      <Text textAlign="center">
        If you no longer have access to this email account, please return to
        login and enter a new email address.
      </Text>
      <Text important fontSize="15px" padding="14px" >
        <Link style={{ zIndex: 1, textDecoration: 'none' }} to={"/" + eventcode + "/login"}>Return to Login</Link>
      </Text>
        <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
            <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
                AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
                <span style={{ color: "red" }}>
                <a><Link style={{ textDecoration: 'none' }} to={"/" + eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
              </span>
            </div>
        </div>
    </PageBodyContainer>
  )
}

export default ResetPasswordEmail
