import styled from "@emotion/styled"
type Props = {
  fontWeight?: number
}
const Label = styled("label")<Props>`
  font-size: 16px;
  color: ${(props) => props.theme.colors.important};
  text-align: left;
  margin: 8px 10px;
  font-weight: ${(props) => props.fontWeight || 400};
`

export default Label
