import styled from "@emotion/styled";
type Props = {
  justifyContent?: string;
  marginTop?: string | number;
  alignItems?: string;
  height?: string;
};
const PageBodyContainer = styled.div<Props>`
  width: 100%;
  height: ${(props) => props.height || "unset"};
  align-items: ${(props) => props.alignItems || "center"};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent || ""};
  margin-top: ${(props) => props.marginTop || ""};
`;

export default PageBodyContainer;
