import {getEndPoint, SUBMIT_BID, VERIFY_EMAIL} from "./enpoints"
import { client } from "./fetchData"

const verifyAccountPost = async (verifyToken: String) => {
  // const token = localStorage.getItem("token")
  const verifyTokenBody = { 'token': verifyToken }
  return await client.post(getEndPoint(VERIFY_EMAIL), verifyTokenBody, {
    headers: {
      // Authorization: "Bearer " + token
    }
  })
}

export { verifyAccountPost }
