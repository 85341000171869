import styled from "@emotion/styled"
import React, {ChangeEvent, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import {useNavigate, useParams} from "react-router-dom"
import { resetPassword } from "../../redux/slices/userSlice"
import { getEndPoint, RESET_PASSWORD } from "../../util/api/enpoints"
import Button from "../CommonStyles/Button"
import Form from "../CommonStyles/Form"
import InputsContainer from "../CommonStyles/InputsContainer"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"
import {IonToast} from "@ionic/react";
import waveBluePng from "../../assets/img/svg/wave-blue.png";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

interface Params {
  eventcode: string
}

const ResetPasswordAccount: React.FC = () => {
  const { eventcode } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userData = useSelector((state: any) => state.user)
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [toastObj, setToast] = useState({ display: false, message: '', goBack: false });

  const goBack = () => {
    const mypath = '/' + eventcode + '/my-account'
    navigate(mypath)
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()

    if (password === password2) {
      const url = getEndPoint(RESET_PASSWORD)
      const domainName =  window.location.hostname
      const domainNames = domainName.split('.')
      const auctioncode = (domainNames && domainNames.length > 0) ? domainNames[0] : 'app'

      try {
        const response: any = await dispatch(
            resetPassword({
              url,
              password,
              auctioncode
            })
        )
        if (response.error) {
          throw new Error("Some Server Error !")
        } else if (response.payload) {
          const responseJSON = JSON.parse(response.payload.jsonString)

          if (responseJSON.status === "success") {
            setToast({ display: true, message: 'Password was reset successfully.', goBack: true })
          }
          else {
            setToast({ display: true, message: 'Password reset failed ' + responseJSON.message, goBack: false })
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
  return (
    <PageBodyContainer>
      <EventNameContainer>
        <Text
            fontSize="35px"
            fontWeight="bold"
            textAlign="center"
            padding="24px 0 16px 0"
        >
          Create a New Password
        </Text>
      </EventNameContainer>
      <WaveContainer></WaveContainer>

      <Form onSubmit={onSubmit}>
        <InputsContainer>
          {/*<Text padding="8px" fontSize="24px" fontWeight="bold" color="#333333">Create a New Password</Text>*/}
          <Text padding="8px" fontSize="16px" color="#666666">Email Address</Text>
          <Text padding="8px" fontSize="14px" color="#666666">{userData.email}</Text>
          <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">*Password</Text>
          <IonToast
              isOpen={toastObj.display}
              onDidDismiss={() => goBack()}
              message={toastObj.message}
              cssClass={'custom-toast'}
              duration={5000}
          />
          <Input padding-left="10px"
            type="password"
            id="password"
            placeholder="Enter your password here"
            name="password"
            value ={password}
            onChange = {(e)=>setPassword(e.target.value)}
          ></Input>
          <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">*Confirm Password</Text>
          <Input
              type="password"
              id="password2"
              placeholder="Verify Password"
              name="password2"
              value ={password2}
              onChange = {(e)=>setPassword2(e.target.value)}
          ></Input>
          <Button style={{ zIndex: 100 }} type="submit" height="35px" width="280px">
            Reset Password
          </Button>
          <Text padding="8px" fontSize="14px" fontWeight="bold" color="#333333" textAlign="center">*Required Fields</Text>
        </InputsContainer>
      </Form>
    </PageBodyContainer>
  )
}

export default ResetPasswordAccount
