import styled from "@emotion/styled"
import React, { PropsWithChildren } from "react"
import Img from "./Img"
import LogoContainer from "./LogoContainer"
import LogoWrapper from "./LogoWrapper"
import waveBluePng from "../../assets/img/svg/wave-blue.png"
import Text from "../../pages/CommonStyles/Text"
import Menu from "../Menu/Index"

type Props = {
  eventResponse: any
  isEventLoading: boolean
  showWelcomeMsg: boolean
  userInfo?: any
  title?: string
}

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 40px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const SecondaryHeader: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
) => {
  const { eventResponse, isEventLoading, children } = props
  return (
    <>
      {!isEventLoading && (
        <>
          {/*<Menu></Menu>*/}
          <LogoWrapper>
            <LogoContainer>
              <Img
                alt="logo"
                src={eventResponse.logourl}
              />
            </LogoContainer>
          </LogoWrapper>
          {props.title && (
            <>
              <EventNameContainer>
                <Text
                  fontSize="35px"
                  fontWeight="bold"
                  textAlign="center"
                  padding="24px 0 16px 0"
                >
                  {props.title}
                </Text>
                {children}
              </EventNameContainer>
              <WaveContainer></WaveContainer>
            </>
          )}
        </>
      )}
    </>
  )
}

export default SecondaryHeader
