import React, {useCallback, useEffect, useState} from "react"
import {loadStripe} from '@stripe/stripe-js';

interface Params {
  eventcode: string
  orders: string
  historyid: string
}

const StripeCheckout = () => {
  const [stripePromise, setStripePromise] = useState(null)
  const apiKey = 'pk_test_51P2E9hEx8pB9i4Ty1Or1lBsLtvKvVPdYtr6MWSQVQTCbyUFeQzLytcwsuHjtvu5ote3nYFfye4cQFmAyIQlfGlcv00kAWBhGW2'


  const loadStripeAccount = async () => {
    setStripePromise(await loadStripe(apiKey));
  }

  useEffect(() => {
    if (stripePromise) {
      const elements = stripePromise.elements(options);
      const paymentElement = elements.create('payment', {
        layout: {
          type: 'tabs',
          defaultCollapsed: false,
        },
      });
      paymentElement.mount('#payment-element');
    }
  }, [stripePromise])

  useEffect(() => {
    loadStripeAccount()
  }, [])

  const options = {
    mode: 'payment',
    amount: 100,
    paymentMethodCreation: 'manual',
    currency: 'usd',
    paymentMethodTypes: ['card', 'us_bank_account'],
    setupFutureUsage: 'off_session',
  };
 
  return (
    <>
      <div id="payment-element"></div>
    </>
  )
}

export default StripeCheckout
