import { heart, heartOutline, ribbon } from "ionicons/icons"
import moment from "moment"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate, useParams} from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { IonIcon, IonLoading, IonRow } from "@ionic/react"

import { IconLosingBid } from "../../components/Icons/IconLosingBid"
import { IconWinningBidder } from "../../components/Icons/IconWinningBidder"
import { toggleWatchlistItem } from "../../redux/slices/auctionItemsSlice"
import { WATCH_LIST, getEndPoint } from "../../util/api/enpoints"
import FlexRow from "../CommonStyles/FlexRow"
import Text from "../CommonStyles/Text"
import { theme } from "../CommonStyles/Theme"
import { AuctionItem, UserBidStatus } from "./Index"
import { ErrorMessage, FlexColAuction, HeartIconButton, IconBackground, ImgWelcome, LastBidContainer } from "./styledComponents"
import {DateTime} from "luxon";

interface Params {
  eventcode: string
}

export const CardViewItems = ({ state, items }) => {
  const local = DateTime.local()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { eventcode } = useParams()
  const accountStatus = useSelector((state: any) => state.user.accountstatus);
  const onItemClick = (itemId: number) => {
    navigate(`/${eventcode}/auction-items/${itemId}`)
  }

  const getLocalDateString = (dateString) => {
      const centralDate = DateTime.fromISO(dateString,{zone: 'America/Chicago'})
      const localDate = centralDate.setZone(local.zoneName)
      const localDateString = localDate.toString()
      return localDateString
  }

  const itemStatus = (item: any) => {

    return (item.bidstatus === "biddingclosed" || (item.quantityavailable === 0 && item.unlimitedquantity === false))
      ? "Closed"
      : closingTime(getLocalDateString(item.closingcst))
  }

  const closingTime = (closing: string) => {
    const then: any = moment(closing)
    const now: any = moment()
    const diff = moment.duration(then.diff(now))
    var days = Math.floor(diff.asDays())
    var hours = diff.hours()
    var min = diff.minutes()
    if (days < 1){
      return `${hours}h ${min}m`
    }
    else if (days < 1 && hours < 1 && min < 1) {
      return `1m`
    }
    return `${days}d ${hours}h`
  }

  const handleBuyItNowCard = (status: UserBidStatus, item: any) => {
    if (item.quantityavailable > 0 || item.unlimitedquantity) {
      if (item.buyitnowavailable && item.buyitnowonly === false) {
        return (
            <div>
              <FlexRow justifyContent="space-between" padding={status === 'highBidder' || status === 'losingBidder' ? '8px 12px' : '8px'}>
                <LastBidContainer status={status} className="lastBidContainer">
                  <Text fontSize="20px">${item.lastbid}</Text>
                </LastBidContainer>
              </FlexRow>
              <FlexRow justifyContent="space-between" padding="8px">
                <Text fontSize="12px" color="#A2A2A2">
                  ${item.buyitnowprice} Buy Now
                </Text>
                <Text fontSize="12px" color="#A2A2A2">
                  {itemStatus(item)}
                </Text>
              </FlexRow>
            </div>
        )
      }
      else if (item.buyitnowonly) {
        return (
            <FlexRow justifyContent="space-between" padding="8px">
              <Text fontSize="12px" color="#A2A2A2">
                ${item.buyitnowprice} Buy Now
              </Text>
              <Text fontSize="12px" color="#a2a2a2">
                {itemStatus(item)}
              </Text>
            </FlexRow>
        )
      }
      else {
        if (item.itemisliveonly) {
          return (
              <FlexRow justifyContent="space-between" padding={status === 'highBidder' || status === 'losingBidder' ? '12px' : '8px'}>
                <LastBidContainer status={status} className="lastBidContainer">
                  <Text fontSize="20px">Live Auction</Text>
                </LastBidContainer>
                <Text fontSize="12px" color="#a2a2a2">
                  {itemStatus(item)}
                </Text>
              </FlexRow>
          )
        }
        else {
          return (
              <FlexRow justifyContent="space-between" padding={status === 'highBidder' || status === 'losingBidder' ? '12px' : '8px'}>
                <LastBidContainer status={status} className="lastBidContainer">
                  <Text fontSize="20px">${item.lastbid}</Text>
                </LastBidContainer>
                <Text fontSize="12px" color="#a2a2a2">
                  {itemStatus(item)}
                </Text>
              </FlexRow>
          )
        }

      }
    }
    else {
      if (item.buyitnowavailable) {
        return (
            <div>
              <FlexRow justifyContent="space-between" padding={status === 'highBidder' || status === 'losingBidder' ? '8px 12px' : '8px'}>
                <LastBidContainer status={status} className="lastBidContainer">
                  <Text fontSize="20px">SOLD OUT</Text>
                </LastBidContainer>
              </FlexRow>
              <FlexRow justifyContent="space-between" padding="8px">
                <Text fontSize="12px" color="#A2A2A2">
                  ${item.buyitnowprice} Buy Now
                </Text>
                <Text fontSize="12px" color="#A2A2A2">
                  {itemStatus(item)}
                </Text>
              </FlexRow>
            </div>
        )
      }
      else {
        return (
            <FlexRow justifyContent="space-between" padding={status === 'highBidder' || status === 'losingBidder' ? '12px' : '8px'}>
              <LastBidContainer status={status} className="lastBidContainer">
                <Text fontSize="20px">SOLD OUT</Text>
              </LastBidContainer>
              <Text fontSize="12px" color="#a2a2a2">
                {itemStatus(item)}
              </Text>
            </FlexRow>
        )
      }
    }
  }

  if (state === "loading") {
    return (
      <IonRow>
        <IonLoading isOpen></IonLoading>
      </IonRow>
    )
  }

  if (state === "failed") {
    return (
      <ErrorMessage>
        Sorry, we are unable to fetch auction items at the moment.
      </ErrorMessage>
    )
  }

  const onWatchListClick = (itemid: number) => (e: any) => {
    e.stopPropagation()
    dispatch(toggleWatchlistItem({ url: getEndPoint(WATCH_LIST), itemid }))
  }

  const renderIcon = (
    status: UserBidStatus = "default",
    item: AuctionItem
  ) => {
    if (status === "winner") {
      return (
        <div style={{ position: 'relative', width: 100, height: 100, }}>
          <div style={{ background: '#1CD182', 
            transform: 'rotate(45deg) translate(0px, -75px)', 
            top: -40, left: 0, right: -40, bottom: 0, position: 'absolute'
           }}>
          </div>
            <IonIcon
              icon={ribbon}
              slot="icon-only"
              color="dark"
              style={{ width: "1.5rem", height: "1.5rem", top: 0, right: 0, position: 'absolute' }}
            />
      </div>
      )
    }

    if (status === "highBidder") {
      return <div style={{ marginTop: 5}}>        
          <IconWinningBidder />
      </div>
    }

    if (status === "losingBidder") {
      return <IconBackground>
        <IconLosingBid width="1.5rem" height="1.5rem" />
      </IconBackground>
    }

    if (accountStatus !== 'guest') {
      return (
          <HeartIconButton
              active={item.watchlist}
              position="static"
              onClick={onWatchListClick(item?.itemid)}
              width="1.7rem"
              height="1.7rem"
          >
            <IonIcon
                icon={item.watchlist ? heart : heartOutline}
                slot="icon-only"
                color={item.watchlist ? "tertiary" : "medium"}
                style={{width: "1.5rem", height: "1.5rem"}}
            />
          </HeartIconButton>
      )
    }
  }

  const getStatus = (item: AuctionItem): UserBidStatus => {
    const stillOpen =
        moment().diff(moment(getLocalDateString(item.closingcst))) < 0

    if (item.currentlywinningbid && item.accountbiddingon && stillOpen) {
      return "highBidder"
    }

    if (item.currentlywinningbid && item.accountbiddingon && !stillOpen) {
      return "winner"
    }

    if (!item.currentlywinningbid && item.accountbiddingon) {
      return "losingBidder"
    }
    return "default"
  }

  return items?.map((item: AuctionItem) => {
    const status = getStatus(item)

    return (
      <FlexColAuction
        onClick={() => onItemClick(item.itemid)}
        key={item.itemid}
        color="#4c4c4c"
        width="150px"
        height="250px"
        borderRadius="15px"
        backgroundColor="#ffffff"
        // filter={`drop-shadow(0px 3px 6px ${theme.colors.dropshadow})`} // Causes problems in Safari
        flexWrap="nowrap"
        justifyContent="space-between"
        style={{ position: "relative", overflow: 'hidden', border: status === 'winner' ? '3px solid #1CD182' : 'none' }}
      >
        <FlexRow>
          <LazyLoadImage
              alt="logo"
              height="125px"
              style={{ objectPosition: 'center', objectFit: 'cover' }}
              // width="100%"
              src={item.imageurl} // use normal <img> attributes as props
              effect="blur"
          />

          <div style={{ position: 'absolute', top: 8, right: 8}}>
            {renderIcon(status, item)}
          </div>
        </FlexRow>

        <Text fontSize="14px" fontWeight="300" padding="5px">
          {item.name}
        </Text>

        {state !== "loading" &&
          handleBuyItNowCard(status, item)
        }

      </FlexColAuction>
    )
  })
}


