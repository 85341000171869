import { heart, heartOutline, logoUsd, ribbon } from "ionicons/icons";
import moment from "moment";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

import {
  IonChip,
  IonCol,
  IonIcon,
  IonLabel,
  IonLoading,
  IonRow,
} from "@ionic/react";

import { IconLosingBid } from "../../components/Icons/IconLosingBid";
import { IconWinningBidder } from "../../components/Icons/IconWinningBidder";
import { toggleWatchlistItem } from "../../redux/slices/auctionItemsSlice";
import { WATCH_LIST, getEndPoint } from "../../util/api/enpoints";
import Text from "../CommonStyles/Text";
import { AuctionItem, UserBidStatus } from "./Index";
import {
  ErrorMessage,
  HeartIconButton,
  LastBidContainer,
  ListIconContainer,
  ListItemRow,
} from "./styledComponents";
import {DateTime} from "luxon";

interface Params {
  eventcode: string
}

export const ListViewItems = ({ state, items }) => {
  const local = DateTime.local()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventcode } = useParams()
  const accountStatus = useSelector((state: any) => state.user.accountstatus);

  const getLocalDateString = (dateString) => {
    const centralDate = DateTime.fromISO(dateString,{zone: 'America/Chicago'})
    const localDate = centralDate.setZone(local.zoneName)
    const localDateString = localDate.toString()
    return localDateString
  }

  const closingTime = (closing: string) => {
    const then: any = moment(closing);
    const now: any = moment();
    const diff = moment.duration(then.diff(now));
    var days = Math.floor(diff.asDays());
    var hours = diff.hours();
    var min = diff.minutes()
    if (days < 1){
      return `${hours}h ${min}m`
    }
    else if (days < 1 && hours < 1 && min < 1) {
      return `1m`
    }
    return `${days}d ${hours}h`;
  };

  const itemStatus = (item: any) => {
    return (item.bidstatus === "biddingclosed" || (item.quantityavailable === 0 && item.unlimitedquantity === false))
        ? "Closed"
        : closingTime(getLocalDateString(item.closingcst));
  };

  const onWatchListClick = (itemid: number) => (e: any) => {
    e.stopPropagation();
    dispatch(toggleWatchlistItem({ url: getEndPoint(WATCH_LIST), itemid }));
  };

  const onItemClick = (itemId: number) => {
    navigate(`/${eventcode}/auction-items/${itemId}`);
  };

  const handleBuyItNowList = (item: any, status: UserBidStatus) => {
    if (item.quantityavailable > 0 || item.unlimitedquantity) {
      if (item.buyitnowavailable && item.buyitnowonly === false) {
        return (
            <div>
              <IonRow>
                <IonCol style={{ display: "flex" }}>
                  <LastBidContainer status={status} className="lastBidContainer">
                    <Text fontSize="14px" fontWeight={600}>
                      ${item.lastbid}
                    </Text>
                  </LastBidContainer>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Text fontSize="12px" color="#a2a2a2">
                    ${item.buyitnowprice}
                  </Text>
                  <Text fontSize="12px" color="#a2a2a2">
                    Buy Now
                  </Text>
                </IonCol>
              </IonRow>
            </div>
        );
      } else if (item.buyitnowonly) {
        return (
            <IonRow>
              <IonCol>
                <Text fontSize="12px" color="#a2a2a2">
                  ${item.buyitnowprice}
                </Text>
              </IonCol>
              <IonCol>
                <Text fontSize="12px" color="#a2a2a2">
                  $$$
                </Text>
              </IonCol>
            </IonRow>
        );
      } else {
        if (item.itemisliveonly) {
          return (
              <IonRow>
                <IonCol>
                  <Text fontSize="14px">Live Auction</Text>
                </IonCol>
              </IonRow>
          )
        }
        else {
          return (
              <IonRow>
                <IonCol>
                  <Text fontSize="14px">${item.lastbid}</Text>
                </IonCol>
              </IonRow>
          )
        }
      }
    }
    else {
      if (item.buyitnowavailable) {
        return (
            <div>
              <IonRow>
                <IonCol style={{ display: "flex" }}>
                  <LastBidContainer status={status} className="lastBidContainer">
                    <Text fontSize="14px" fontWeight={600}>
                      SOLD OUT
                    </Text>
                  </LastBidContainer>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Text fontSize="12px" color="#a2a2a2">
                    ${item.buyitnowprice}
                  </Text>
                  <Text fontSize="12px" color="#a2a2a2">
                    Buy Now
                  </Text>
                </IonCol>
              </IonRow>
            </div>
        );
      } else {
        return (
            <IonRow>
              <IonCol>
                <Text fontSize="14px">SOLD OUT</Text>
              </IonCol>
            </IonRow>
        );
      }
    }
  };

  const renderIconOnList = (
    status: UserBidStatus = "default",
    item: AuctionItem
  ) => {
    if (status === "winner") {
      return (
        <IonIcon
          icon={ribbon}
          slot="icon-only"
          color="dark"
          style={{ width: "1.5rem", height: "1.5rem" }}
        />
      );
    }

    if (status === "highBidder") {
      return <IconWinningBidder />;
    }

    if (status === "losingBidder") {
      return <IconLosingBid width="1.5rem" height="1.5rem" />;
    }

    if (accountStatus !== 'guest') {
      return (
          <HeartIconButton
              active={item.watchlist}
              position="static"
              onClick={onWatchListClick(item?.itemid)}
              width="1.7rem"
              height="1.7rem"
          >
            <IonIcon
                icon={item.watchlist ? heart : heartOutline}
                slot="icon-only"
                color={item.watchlist ? "tertiary" : "medium"}
                style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </HeartIconButton>
      );
    }

  };

  if (state === "loading") {
    return (
      <IonRow>
        <IonLoading isOpen></IonLoading>
      </IonRow>
    );
  }

  if (state === "failed") {
    return (
      <ErrorMessage>
        Sorry, we are unable to fetch auction items at the moment.
      </ErrorMessage>
    );
  }

  return items.map((item: AuctionItem, i) => {
    const getStatus = (): UserBidStatus => {
      const stillOpen =  
        moment().diff(moment(getLocalDateString(item.closingcst))) < 0
      if (item.currentlywinningbid && item.accountbiddingon && stillOpen) {
        return "highBidder"
      }
      if (item.currentlywinningbid && item.accountbiddingon && !stillOpen) {
        return "winner"
      }
      if (!item.currentlywinningbid && item.accountbiddingon) {
        return "losingBidder"      
      }
      return "default"
    }
    const status = getStatus()
    return (
      <ListItemRow
        status={status}
        guest={accountStatus === 'guest'}
        key={item.itemid}
        onClick={() => onItemClick(item.itemid)}
      >
        {accountStatus !== 'guest' &&
            <>
              <ListIconContainer status={status} guest={accountStatus === 'guest'} style={{ paddingLeft: "0.25rem" }}>
                {renderIconOnList(status, item)}
              </ListIconContainer>
            </>
        }

        <div>
          <Text fontSize="12px" style={{ paddingLeft: "0.50rem" }}>{item.name}</Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyItems: "center",
          }}
        >
          {item.tags &&
            item.tags.split(",").map((tag, i) => (
              <IonChip color="black" style={{ padding: 5, height: 24 }}>
                <IonLabel color="black" style={{ fontSize: 12 }}>
                  {tag}
                </IonLabel>
              </IonChip>
            ))}
        </div>
        <div>{handleBuyItNowList(item, status)}</div>
        <div style={{ paddingRight: "0..25rem" }}>
          <Text fontSize="12px">{itemStatus(item)}</Text>
        </div>
      </ListItemRow>
    );
  });
};
