import styled from "@emotion/styled"
import { mq } from "../../constants"

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq[0]} {
    justify-content: unset;
    overflow-y: auto;
  }
  ${mq[2]} {
    //justify-content: space-evenly;
    justify-content: unset;
    overflow-y: auto;
  }
`

export default Form
