import styled from '@emotion/styled'
import { IonSpinner } from '@ionic/react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect, useNavigate, useParams } from 'react-router-dom'
import waveBluePng from '../../assets/img/svg/wave-blue.png'
import useFormFields from '../../hooks/FormField'
import {fetchEventData, setEventCode, setAuctionCode, setForceLogout} from '../../redux/slices/eventSlice'
import { setPrivacyEventCode } from '../../redux/slices/privacyPolicySlice'
import {sendUserInfo, updateStateOnLogout, updateUserInfo, verifyEmail} from '../../redux/slices/userSlice'
import { getEndPoint } from '../../util/api/enpoints'
import Button from '../CommonStyles/Button'
import Form from '../CommonStyles/Form'
import InputsContainer from '../CommonStyles/InputsContainer'
import Label from '../CommonStyles/Label'
import PageBodyContainer from '../CommonStyles/PageBodyContainer'
import Text from '../CommonStyles/Text'
import jwt_decode from "jwt-decode";
import {disconnected} from "../../redux/slices/signalrSlice";
import {Link} from "react-router-dom";
import {getURL} from "../../constants";
import store from "../../redux/store";
import axios from "axios";
import {getConfig} from "../../AppConfig";

interface Params {
  eventcode: string
  loginguid?: string
}

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

/* extract validators in a separate file */

const validateEmail = (email: string) => {
  const errors = []

  if (email.length < 5) {
    errors.push('Email should be at least 5 charcters long')
  }
  if (email.split('').filter((x) => x === '@').length !== 1) {
    errors.push('Email should contain a @')
  }
  if (email.indexOf('.') === -1) {
    errors.push('Email should contain at least one dot')
  }

  return errors
}

const Input = styled('input')`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const EventPage: React.FC = () => {
  console.log(window.location.pathname)
  const { eventcode, loginguid } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const event = useSelector((state: any) => state.event)
  const user = useSelector((state: any) => state.user)
  const token = localStorage.getItem("token")
  const signalrState = useSelector((state: any) => state.signalr)
  const userAccountStatus = useSelector((state: any) => state.user.accountstatus)
  const [homemessage, setHomeMessasge] = useState('')

  const { apiUrl } = getConfig()
  const passwordlessprocessURL = `${apiUrl}/passwordlessprocess/`

  const domainName =  window.location.hostname
  const domainNames = domainName.split('.')
  const auctioncode = (domainNames && domainNames.length > 0) ? domainNames[0] : 'app'

  if (token) {
    const decoded: any = jwt_decode(token)
    // Clear token and logout becuase the token eventcode does not match param eventcode
    console.log(eventcode)
    console.log(user.email)
    if ((decoded.eventcode.toString() !== eventcode.toString() || decoded.email !== user.email) && !decoded.isguest && !loginguid) {
      dispatch(updateStateOnLogout())
      localStorage.removeItem("token")
    }
  }

  useEffect(() => {
    if (loginguid && loginguid.length > 0) {
      const url = passwordlessprocessURL + loginguid
      axios.get(url, {headers: {}}).then((response) => {
        if (response) {
          const dataJson = JSON.parse(response.data.jsonString)
          if (dataJson.status === 'expired') {
            setHomeMessasge(dataJson.message)
          }
          else {
            processToken(dataJson)
            navigate('/' + eventcode + '/welcome/')
            setHomeMessasge(event.homemessage)
          }
        }
      })
    }
  }, [loginguid])

  const emailInputRef = useRef<any>()
  const { formFields, createChangeHandler } = useFormFields({
    email: ''
  })

  useEffect(() => {
    // console.log("SETFORCELOGOUT")
    store.dispatch(setForceLogout(false))
  }, [])

  useEffect(() => {
    if (signalrState.connected) {
      if (userAccountStatus === 'guest') {
        navigate('/' + eventcode + '/welcome/')
      }
    }
  }, [signalrState, userAccountStatus])

  const [errors, setErrors] = useState<string[]>([])
  dispatch(setEventCode(eventcode))
  dispatch(setAuctionCode(auctioncode))
  dispatch(setPrivacyEventCode(eventcode))
  const eventStatus = useSelector((state: any) => state.event.status)
  const eventData = useSelector((state: any) => state.event.eventData)

  // effect to focus the email address field
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus()
    }
  })

  useEffect(() => {
    if (eventStatus === 'idle') {
      const url = getEndPoint('EVENT_ENDPOINT')
      const eventData = {
        url,
        eventcode: eventcode,
        auctioncode: auctioncode
      }
      dispatch(fetchEventData(eventData))
    }
  }, [eventStatus, eventcode, auctioncode])

  const onGuestLogin = async (e: any) => {
    e.preventDefault()

    if (localStorage.getItem('token')) {
      localStorage.removeItem('token')
    }
    const url = getEndPoint('GUEST_LOGIN_URL')
    const userInfo = {
      url,
      eventcode: event.eventcode,
      auctioncode: event.auctioncode
    }
    try {
      const response: any = await dispatch(sendUserInfo(userInfo))
      if (response.error) {
        throw new Error('Internal server Error')
      }
      else if (response.payload) {
        const responseJSON = JSON.parse(response.payload.jsonInfo)
        // console.log(responseJSON)
        if (responseJSON.status === 'success') {
          setErrors([])
          const token = responseJSON.token
          // console.log(token)
          const accountstatus = responseJSON.accountstatus
          localStorage.setItem('token', token)
          dispatch(
              updateUserInfo({
                accountstatus,
                authorizebidder: false,
                isLoggedIn: true,
                firstname: 'Guest'
              })
          )
          dispatch({
            type: 'TOKEN_CONNECT',
            payload: {
              url: getURL(),
              token: token
            }
          })
          console.log(localStorage.getItem('token'))
          console.log('finish')
        }
        else if (responseJSON.status === 'failure') {
          setErrors([responseJSON.message])
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()

    const email: string = formFields.email
    const emailErrors = validateEmail(email)

    if (emailErrors.length > 0) {
      setErrors(() => emailErrors)
    } else {
      setErrors([])
    }

    if (emailErrors.length <= 0) {
      // call send userinfo
      const url = getEndPoint('JOIN_EMAIL')

      const result: any = await dispatch(verifyEmail({ url, email, eventcode, auctioncode }))
      if (result.error) {
        // add logic to say server failed to authenticate email !
        console.log('internal error failed to connect to the server !')
        return
      }
      const response = JSON.parse(result.payload.jsonString)
      const accountStatus = response.accountstatus
      let redirectTo = ''
      // should the routes match the status?
      if (accountStatus === 'exists') {
        redirectTo = '/' + eventcode.toLowerCase() + '/login'
      } else if (accountStatus === 'doesnotexist') {
        // Here we need to create an account
        redirectTo = '/' + eventcode + '/create-account/'
      }
      dispatch(updateUserInfo({ email }))
      navigate(redirectTo)
    }
  }

  const processToken = (jsonData) => {
    if (jsonData.status === 'success') {
      setErrors([])
      const token = jsonData.token
      const accountstatus = jsonData.accountstatus
      const setup = jsonData.setupcomplete
      const userid = jsonData.userid
      localStorage.setItem('token', token)
      dispatch(
          updateUserInfo({
            accountstatus,
            accountSetupComplete: setup,
            userid,
            firstname: jsonData.firstname,
            lastname: jsonData.lastname,
            authorizebidder: jsonData.authorizebidder,
            isLoggedIn: true,
            notifications: jsonData.notifications,
            eventcode: jsonData.eventcode,
            auctioncode: jsonData.auctioncode
          })
      )
      dispatch({
        type: 'TOKEN_CONNECT',
        payload: {
          url: getURL(),
          token: token
        }
      })
    }
  }

  return (
    <>
      {eventStatus === 'succeeded' && eventData.status === 'success' && (
        <PageBodyContainer>
          <EventNameContainer>
            {event.eventname && (
                <Text
                    fontSize="35px"
                    fontWeight="bold"
                    textAlign="center"
                    padding="24px 0 16px 0"
                >
                  {event.eventname}
                </Text>
            )}

            {eventData.eventflag === "eventnotopen" ?
                <Text fontSize="20px" textAlign="center" padding="24px">
                  <div
                      dangerouslySetInnerHTML={{
                        __html: event.eventData.preeventtext
                      }}
                  />
                </Text>
                :
                <Text fontSize="20px" textAlign="center" padding="24px">
                  <div
                      dangerouslySetInnerHTML={{
                        __html: (homemessage || event.homemessage)
                      }}
                  />
                </Text>
            }

          </EventNameContainer>
          <WaveContainer></WaveContainer>

          {eventData.eventflag !== "eventnotopen" &&
              <Form onSubmit={onSubmit}>
                {errors.length > 0 &&
                    errors?.map((err) => <div key={err}>Error : {err}</div>)}
                <InputsContainer>
                  <Label htmlFor="email">Email Address</Label>
                  <Input
                      ref={emailInputRef}
                      type="text"
                      id="email"
                      placeholder="Enter your email here"
                      name="email"
                      autoFocus={true}
                      value={formFields.email}
                      onChange={createChangeHandler('email')}
                  ></Input>
                </InputsContainer>
                <Button style={{zIndex: 1}} type="submit">Join the Fun</Button>

                {event.eventallowguestentry &&
                  <div style={{ marginBottom: "20px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 10px", textAlign: "center", fontSize: 14 }}>
                    <Link style={{ textDecoration: 'none' }} onClick={onGuestLogin} to="">Browse auction as guest</Link>
                  </div>
                }
              </Form>
          }
        </PageBodyContainer>
      )}
      {eventStatus === 'loading' && (
        <PageBodyContainer justifyContent="center">
          <IonSpinner color="primary" name="bubbles"></IonSpinner>
        </PageBodyContainer>
      )}
      {(eventStatus === 'failed' || eventData.status === 'failure') && (
          redirect("/" + eventcode + "/not-found/")
      )}
      <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
        <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
          AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
          <span style={{ color: "red" }}>
            <Link style={{ textDecoration: 'none' }} to={"/" + eventcode + "/privacy-policy"}>Privacy Policy</Link>
          </span>
        </div>
      </div>
    </>
  )
}

export default EventPage
