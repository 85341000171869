import { Theme } from "@emotion/react";

export const theme: Theme = {
  colors: {
    primary: "#4c4c4c",
    // primary: "#5732db",
    important: "#D2222D",
    background: "#f2f2f2",
    header: {
      background: "#ffffff",
      logoWrapper: "#ffffff",
    },
    white: "#ffffff",
    auctionRed: "#d2222d",
    dropshadow: "#0000004D",
  },
  components: {
    navBarHeight: "64px",
  },
};
