import styled from "@emotion/styled"

const NavMenuItem = styled.div`
  display: flex;
  color: #666666;
  border-bottom: 2px solid #f7f7f7;
  height: 53px;
  width: 100%;
  align-items: center;
`

export default NavMenuItem
