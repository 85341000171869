import { getEndPoint, SUBMIT_BID } from "./enpoints"
import { client } from "./fetchData"

type ItemDetails = {
  itemid: number
  userid: number
  bid: number | string
  buyitnow: boolean
  autobid?: boolean
  maxbid?: number | string
  increment?: number | string
  quantity?: number
}

const submitBid = (itemDetails: ItemDetails) => {
  const token = localStorage.getItem("token")
  return client.post(getEndPoint(SUBMIT_BID), itemDetails, {
    headers: {
      Authorization: "Bearer " + token
    }
  })
}

export { submitBid }
