import styled from "@emotion/styled"
import { mq } from "../../constants"

type Props = {
  height?: string
  justifyContent?: string
  flexWrap?: string
  alignItems?: string
  padding?: string
  margin?: string
  border?: string
  borderRadius?: string
  backgroundColor?: string
  filter?: string
  width?: string
  flex?: string
}
/* move commented media queries to component */

const FlexCol = styled.div<Props>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height || "100px"};
  justify-content: ${(props) => props.justifyContent || "center"};
  flex-wrap: ${(props) => props.flexWrap || "wrap"};
  align-items: ${(props) => props.alignItems || "unset"};
  padding: ${(props) => props.padding || "unset"};
  background-color: ${(props) => props.backgroundColor || "unset"};
  border-radius: ${(props) => props.borderRadius || "unset"};
  filter: ${(props) => props.filter || "none"};
  margin: ${(props) => props.margin || "unset"};
  width: ${(props) => props.width || "unset"};
  flex: ${(props) => props.flex || "unset"};
`
export default FlexCol
