import React from "react"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"

const NotFound: React.FC = () => {
  return (
    <PageBodyContainer justifyContent="center">
      <Text important>Error: Event Not Found</Text>
      <Text textAlign={"center"}>
        Please check the link provided to you by the Organization
      </Text>
    </PageBodyContainer>
  )
}

export default NotFound
