import styled from "@emotion/styled"
import { IonCol, IonGrid, IonRow } from "@ionic/react"
import React, { useEffect, useState } from "react"
import {useNavigate, useParams} from "react-router-dom"
import CheckBox from "../../components/CheckBox/Index"
import ButtonItem from "../CommonStyles/ButtonItem"
import Text from "../CommonStyles/Text"
import {useAuthRefresh} from "../../hooks/authenicationHook";

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`
const Span = styled.span`
  padding-left: 0.5em;
`
type ItemOpen = {
  itemid: number
  name: string
  description: string
  itemnum: string
  quantity: number
  amount: number
  location: string
  pickinstructions: string
}

type ItemOpenList = {
  itemid: number
  name: string
  description: string
  itemnum: string
  quantity: number
  amount: number
  location: string
  pickinstructions: string
  checked: boolean
}
const fakeOpenItems = [
  {
    itemid: 32,
    name: "name",
    description: "description",
    itemnum: "customer item #",
    quantity: 1,
    amount: 200.0,
    location: "Table 1",
    pickinstructions: "something"
  },
  {
    itemid: 24,
    name: "name",
    description: "description",
    itemnum: "customer item #",
    quantity: 1,
    amount: 200.0,
    location: "Table 1",
    pickinstructions: "something"
  },
  {
    itemid: 25,
    name: "name",
    description: "description",
    itemnum: "customer item #",
    quantity: 1,
    amount: 200.0,
    location: "Table 1",
    pickinstructions: "something"
  },
  {
    itemid: 26,
    name: "name",
    description: "description",
    itemnum: "customer item #",
    quantity: 1,
    amount: 200.0,
    location: "Table 1",
    pickinstructions: "something"
  },
  {
    itemid: 27,
    name: "name",
    description: "description",
    itemnum: "customer item #",
    quantity: 1,
    amount: 200.0,
    location: "Table 1",
    pickinstructions: "something"
  }
]

interface Params {
  eventcode: string
}

const OpenBids = () => {
  const { eventcode } = useParams()
  useAuthRefresh();
  const navigate = useNavigate()
  const [itemsOpen, setItemsOpen] = useState<ItemOpen[]>([])
  const [itemsOpenList, setItemsOpenList] = useState<ItemOpenList[]>([])
  const [itemSelectCount, setItemSelectCount] = useState(0)

  const elementScroll = document.getElementById("scrollBody")
  if (elementScroll) {
    elementScroll.scroll(0,0)
  }

  useEffect(() => {
    setTimeout(() => {
      setItemsOpen(fakeOpenItems)
    }, 3000)
  }, [])

  useEffect(() => {
    if (itemsOpen && itemsOpen.length) {
      const newList = itemsOpen.map((item) => ({ ...item, checked: false }))
      setItemsOpenList(newList)
    }
  }, [itemsOpen])

  const goToCheckout = () => {
    navigate("/" + eventcode + "/checkout")
  }

  const handleCheckboxChange = (e: any) => {
    const id = parseInt(e.target.id)
    const checked = e.target.checked
    const newList = itemsOpenList.map((item) => {
      if (item.itemid === id) {
        if (checked) {
          setItemSelectCount(itemSelectCount + 1)
        } else {
          setItemSelectCount(itemSelectCount - 1)
        }
        return {
          ...item,
          checked
        }
      } else {
        return item
      }
    })
    setItemsOpenList(newList)
  }
  return (
    <PageContainer>
      {itemsOpenList && itemsOpenList.length > 0 && (
        <>
          <IonGrid onClick={goToCheckout}>
            <IonRow>
              <IonCol>
                <ButtonItem height="45px">Proceed To Checkout</ButtonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
          {itemsOpenList.map((item) => {
            return (
              <IonGrid key={item.itemid}>
                <IonRow>
                  <IonCol size="9">
                    <IonRow>
                      <label>
                        <Text fontSize="14px" fontWeight="800">
                          <CheckBox
                            name="item"
                            checked={item.checked}
                            handleCheckboxChange={handleCheckboxChange}
                            param="item"
                            checkedBackgroundColor="#5732db"
                            uncheckedBackgroundColor="white"
                            height="24px"
                            width="24px"
                            borderRadius="50%"
                            id={item.itemid}
                          ></CheckBox>
                          <Span>Item Name</Span>
                        </Text>
                      </label>
                    </IonRow>
                  </IonCol>
                  <IonCol size="3">
                    <IonRow className="ion-justify-content-end">
                      <Text fontWeight="800" fontSize="20px">
                        $888888
                      </Text>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow style={{ paddingLeft: "35px" }}>
                  <IonCol
                    className="ion-no-padding"
                    style={{ borderBottom: "1px solid #dadada" }}
                  >
                    <Text fontSize="14px" color="#a2a2a2">
                      #8888, Qty 1
                    </Text>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )
          })}
          <IonGrid>
            <IonRow
              className="ion-justify-content-evenly ion-align-items-center"
              style={{
                backgroundColor: "#0000000D",
                height: "45px",
                borderRadius: "10px"
              }}
            >
              <Text fontWeight="800" fontSize="14px" color="#a2a2a2">
                Selected for Checkout ({itemSelectCount}/{itemsOpenList.length}
                ):
              </Text>
              <Text fontSize="14px" fontWeight="800" padding="0 0 0 10px">
                $8888888
              </Text>
            </IonRow>
          </IonGrid>
        </>
      )}
    </PageContainer>
  )
}

export default OpenBids
