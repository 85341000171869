import React, {useEffect, useRef, useState} from "react"
import './styles.css'
import styled from "@emotion/styled"
import {
    IonCol,
    IonRow,
    IonText,
    IonToast,
    IonToggle,
    IonGrid,
    IonItem,
    IonModal,
    IonBackdrop,
    IonAlert, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonLabel, IonFooter, IonButton, useIonModal
} from "@ionic/react"

import { usStates } from "../../constants"
import Button from "../CommonStyles/Button"
import InputsContainer from "../CommonStyles/InputsContainer"
import Label from "../CommonStyles/Label"
import './index.css';
import Text from "../CommonStyles/Text"
import {useDispatch, useSelector} from "react-redux";
import {checkTokenOnRefresh, useUserInfo} from "../../redux/slices/userSlice";
import {getEndPoint, USER_PROFILE} from "../../util/api/enpoints";
import NumberFormat from "react-number-format";
import CheckBox from "../../components/CheckBox/Index"
import {Span} from "../AuctionItems/styledComponents";
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import {Select} from "../ItemDetails/styledComponents";
import ButtonItem from "../CommonStyles/ButtonItem";
import {Link} from "react-router-dom";

type ContainerProps = {
    borderBottom?: string
}

type RightContainerProps = {
    justifyContent?: string
}

const ModalContent = styled.div`
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  height: 32em;
  text-align: left;
  h1 {
    font-family: var(--ion-font-family);
    font-size: 24px/33px;
    font-weight: bold;
  }
  p {
    font-family: var(--ion-font-family);
  }
  letter-spacing: 0px;
  color: #333333;
`

const CellInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //margin-left: 12px;
  //margin-right: 12px;
  margin-top: 10px;
  //padding: 10px;
`

const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 10px;
`
const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`


const FlexRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const MessageModal = ({
                          onDismiss,
                          message,
                      }: {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void;
    message: string;
}) => {
    const inputRef = useRef<HTMLIonInputElement>(null);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Text Messaging Opt-In</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem lines={'none'}>
                    <IonLabel class={'ion-text-wrap'} style={{ fontSize: '18px'}}>You have provided your phone number but have not checked the box to opt-in to event related text messages.</IonLabel>
                </IonItem>
                <IonItem lines={'none'}>
                    <IonLabel class={'ion-text-wrap'} style={{ fontSize: '18px'}}>Are you sure you sure you want to proceed?</IonLabel>
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonRow style={{marginBottom: '20px'}}>
                    <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'cancel')} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'secondary'}>
                            Go Back
                        </IonButton>
                    </IonCol>
                    <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'ok')} size="large" style={{ width: '90%' }} expand="block" fill="solid" color={'primary'}>
                            Proceed without Text Messages
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    );
};

const StepTwo: React.FC<{
    formNotificationState: any
    setNotificationFormState: any
    handleSubmit: any
    formFields: {
        cellphone: string
        allowtexting: boolean
        bidanonymously: boolean
    }
    createChangeHandler: any
    goToNextView: any
    goToPreviousView: any
}> = (props) => {
    const eventData = useSelector((state: any) => state.event)
    const [present, dismiss] = useIonModal(MessageModal, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
        message: '',
    });

    function openTextModal() {
        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'ok') {
                    processToNextStep()
                }
            },
        });
    }

    const [toastMsg, setToastMsg] = useState('');
    const dispatch = useDispatch()
    const user = useUserInfo()
    const [agreeToMessages, setagreeToMessages] = useState(false)
    const [eventTextAlert, setEventTextAlert] = useState(false)
    const eventState = useSelector((state: any) => state.event)
    const { notifications } = user

    const [modalState, setModalState] = useState({
        display: false,
        header: '',
        message: '',
        goBack: false
    })
    // const [formNotificationState, setNotificationFormState] = useState<any>();

    useEffect(() => {
        let notificationState = {}
        eventState?.notificationcolumns.forEach((itemColumn) => {
            notifications?.forEach((item: any) => {
                const keyValue = `toggle${itemColumn.value}${item.notificationrowid}`
                notificationState = {
                    ...notificationState,
                    [keyValue]: item[itemColumn.value]
                }
            })
        })

        props.setNotificationFormState(notificationState);
    }, [notifications, eventState]);

    const onToggleChange = (type) => (e) => {
        e.preventDefault()
        if (e?.target?.checked === undefined) {
            console.log('skip')
        }
        else {
            const keys = props.formNotificationState ? Object.keys(props.formNotificationState) : []
            if (keys && keys.length > 0) {
                let copyState = {...props.formNotificationState}
                copyState[type] = e?.target?.checked
                // console.log(Object.keys(copyState))
                props.setNotificationFormState(copyState)
            }
        }
    }

    useEffect(() => {
        const getUserData = async () => {
            await dispatch(
                checkTokenOnRefresh({ url: getEndPoint(USER_PROFILE) })
            )
        }
        getUserData()
    }, [dispatch, checkTokenOnRefresh, getEndPoint, USER_PROFILE])

    const userData = useSelector((state: any) => state.user)

    const validateFields = (cb: Function) => (e) => {
        e.preventDefault()

        if (props.formFields.cellphone && props.formFields.cellphone.length > 0 && !props.formFields.allowtexting) {
            setEventTextAlert(e)
            openTextModal()
        }
        else {
            setEventTextAlert(e)
            cb(e)
        }
    }

    const processToNextStep = () => {
        props.handleSubmit(eventTextAlert)
    }

    // const handleCheckboxChange = (e: any) => {
    //     setagreeToMessages(e.target.checked)
    // }

    return (
    <>
          <IonToast
            isOpen={Boolean(toastMsg)}
            onDidDismiss={() => setToastMsg('')}
            message={toastMsg}
            duration={3000}
            cssClass={'custom-toast'}
            color="danger"
          />
        <Section>
        {(eventState.eventallowanonymousbidding) && (
            <>
                {/*<IonGrid>*/}
                <IonRow>
                    <IonCol className="ion-align-items-start" size="12">
                        <Text fontSize="20px" fontWeight={600} margin={"20px 0px 0px 0px"}>Bidder Preferences</Text>
                    </IonCol>
                </IonRow>
                <IonRow >
                    <IonCol className="ion-align-items-start" size="10">
                        <Text important fontSize="14px" margin={"10px 0px 0px 0px"}>Bid Anonymously</Text>
                    </IonCol>
                    <IonCol className="ion-align-items-end" size={'2'}>
                        <IonToggle class="ion-float-lg-right"
                                   checked={props.formFields.bidanonymously}
                                   onIonChange={props.createChangeHandler("bidanonymously")}
                        />
                    </IonCol>
                </IonRow>
                <IonRow style={{borderBottom: '1px solid #D3D3D3', margin: "20px 0px 20px 0px"}}></IonRow>
            </>
        )}
                <IonGrid>
                    <IonRow>

                        <IonCol className="ion-align-items-start" size="6">
                            <Text fontSize="20px" fontWeight={600} margin={"0px 0px 20px 0px"}>Notifications</Text>
                        </IonCol>
                        {eventState?.notificationcolumns.map((itemColumn) => {
                            return (
                                <IonCol className="ion-align-items-end" size={'2'}>
                                    <IonText class="ion-float-lg-right" style={{color: 'black', fontSize: 18}}>{itemColumn.title}</IonText>
                                </IonCol>
                            )
                        })}
                    </IonRow>
                    <IonRow>
                        {eventState?.notificationrows.map((itemRow) => {
                            let rowItems = null

                            rowItems = eventState?.notificationcolumns.map((itemColumn) => {
                                if(itemRow[itemColumn.value]) {
                                    const checked = props.formNotificationState && props.formNotificationState[`toggle${itemColumn.value}${itemRow.notificationrowid}`]
                                    return (
                                        <IonCol className="ion-align-items-end" size={'2'}>
                                            <IonToggle class="ion-float-lg-right"
                                                       name={`toggle${itemColumn.value}${itemRow.notificationrowid}`}
                                                       checked={checked}
                                                       onIonChange={onToggleChange(`toggle${itemColumn.value}${itemRow.notificationrowid}`)}
                                            />
                                        </IonCol>
                                    )
                                }
                                else {
                                    return (
                                        <IonCol className="ion-align-items-end" size={'2'}>
                                        </IonCol>
                                    )
                                }
                            })


                            const headerRow = (
                                <>
                                    <IonCol className="ion-align-items-end" size="6"><Text important fontSize="14px">{itemRow.title}</Text></IonCol>
                                    {rowItems}
                                </>
                            )
                            return headerRow
                        })}
                    </IonRow>
                    <IonRow style={{borderBottom: '1px solid #D3D3D3', margin: "20px 0px 20px 0px"}}></IonRow>

                    <IonRow>
                        <IonCol className="ion-align-items-end" size="12">
                            <NumberFormat
                                style={{width: '100%'}}
                                type="tel"
                                id="cellphone"
                                placeholder="Cell Phone Number"
                                name="cellphone"
                                value={props.formFields.cellphone}
                                onChange={props.createChangeHandler("cellphone")}
                                format="(###) ###-####" mask="_"
                                customInput={Input}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-align-items-start" size="6">
                            <Text fontSize="20px" fontWeight={600} margin={"20px 0px 20px 0px"}>Text Messaging Opt-In</Text>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-align-items-start" size="12">
                            {/*<IonItem class={'ion-align-items-end'} lines={'none'} >*/}
                                <div className={'left-align-input'}>
                                    <label>
                                        <CheckBox
                                            name="item"
                                            // checked={agreeToMessages}
                                            // handleCheckboxChange={handleCheckboxChange}
                                            checked={props.formFields.allowtexting}
                                            handleCheckboxChange={props.createChangeHandler("allowtexting")}
                                            param="item"
                                            checkedBackgroundColor="#5732db"
                                            uncheckedBackgroundColor="white"
                                            height="24px"
                                            width="24px"
                                            borderRadius="5px"
                                        >
                                        </CheckBox>
                                    </label>
                                    <Span>I agree to receive text messages through the AuctionSnap platform for notifications and event messages</Span>
                                </div>
                            {/*</IonItem>*/}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-align-items-start" size="12">
                            <Span>At any time, text STOP to unsubscribe from all text messages through AuctionSnap. Standard messaging rates may apply. You can adjust your notification preferences above and within AuctionSnap under your account profile. </Span>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            {/*<CellInputsContainer>*/}
                {/*<Label fontWeight={600} htmlFor="cellPhone">*/}
                {/*    Cell Phone*/}
                {/*</Label>*/}
                {/*<NumberFormat*/}
                {/*    type="tel"*/}
                {/*    id="cellphone"*/}
                {/*    placeholder="Cell Phone Number"*/}
                {/*    name="cellphone"*/}
                {/*    value={props.formFields.cellphone}*/}
                {/*    onChange={props.createChangeHandler("cellphone")}*/}
                {/*    format="(###) ###-####" mask="_"*/}
                {/*    customInput={Input}/>*/}
            {/*</CellInputsContainer>*/}
        </Section>
      <FlexRow>
        <Button
          height="40px"
          width="200px"
          backgroundColor="white"
          color="black"
          style={{ zIndex: 1 }}
          onClick={props.goToPreviousView}
        >
          Back
        </Button>
        <Button style={{ zIndex: 1 }} height="40px" width="200px" onClick={validateFields(props.handleSubmit)}>
          Next
        </Button>
      </FlexRow>
      <FlexRow>
        <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
            <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
                AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
                <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventData.eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
                </span>
            </div>
        </div>
      </FlexRow>
    </>
  )
}

export default StepTwo
