import styled from "@emotion/styled"

type ContainerProps = {
  borderBottom?: string
}

type RightContainerProps = {
  justifyContent?: string
}

export const PageWrapper = styled.div`
  padding: 0 16px;
  //max-width: 700px;
  margin: 0 auto;
`
export const NotificationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: ${(props: ContainerProps) =>
    props.borderBottom || "2px solid #dcdcdc"};
  height: 64px;
`
export const RightContainer = styled.div`
  display: flex;
  margin-left: auto;
  width: 136px;
  justify-content: ${(props: RightContainerProps) =>
    props.justifyContent || "space-between"};
`

export const RightItemContainer = styled.div`
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
`
