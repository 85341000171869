import styled from "@emotion/styled";

type Props = {
  src: any;
  alt: string;
  buyitnow: boolean;
};

const Img = styled("img")<Props>`
  object-fit: contain;
  object-position: center;
  opacity: ${(props: { buyitnow: boolean }) => props.buyitnow ? 0.10 : null};
  height: 250px;
  width: 100%;

  @media screen and (min-width: 768px) {
    object-fit: contain;
    height: 350px;
  }
  @media screen and (min-width: 900px) {
    height: 450px;
  }
`;
export default Img;
