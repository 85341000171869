import styled from "@emotion/styled";

type ImgProps = {
  alt: string;
  src: any;
  height?: string | number;
  width?: string | number;
  margin?: string;
  borderRadius?: string;
};
// Max height and width need to be used for the image as the image is not always square. --jingles
const Img = styled("img")<ImgProps>`
  max-width: 300px;
  max-height: calc(${(props) => props.theme.components.navBarHeight} - 10px);
  margin: ${(props) => props.margin || "unset"};
  border-radius: ${(props) => props.borderRadius || "unset"};
`;

export default Img;
