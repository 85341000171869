import { useSelector } from "react-redux"

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { client } from "../../util/api/fetchData"
import { AppState } from "../store"
import auctionItemDetailsSlice from "./auctionItemDetailsSlice";

export interface SSAuctionItem {
  rownumber: number
  itemid: number
  name: string
  description: string
  longdescription: string
  tags: string
  numberofbids: number
  startingbid: number
  lastbid: number
  open: string
  closing: string
  opencd: string
  closingcd: string
  bidstatus: string
  quantityavailable: number
  quantityunlimited: boolean
  location: string
  imageurl: string
  donatedby: string
  fairmarketvalue: number
  buyitnowprice: number
  highbidder: string
}

type SlideShowItemsState = {
  message: string
  token: string
  auctioneventid: number
  auctionsiteid: number
  auctionitems: SSAuctionItem[]
  activeAuctionItems: SSAuctionItem[]
  activeItem: SSAuctionItem
  status: "idle" | "loading" | "succeeded" | "failed"
  error: any
}


const initialState: SlideShowItemsState = {
  message: "",
  token: "",
  auctioneventid: 0,
  auctionsiteid: 0,
  auctionitems: [],
  activeAuctionItems: [],
  activeItem: null,
  status: "idle",
  error: null,
}

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}
export const fetchSlideshowItemsData = createAsyncThunk(
  "slideshowitems/fetchSlideshowItemsData",
  async (url: string) => {
    const response = await client.get(
      url, {}
    )
    const parsedData = JSON.parse(response.jsonString)
    console.log(parsedData)
    return parsedData
  }
)

export const slice = createSlice({
  name: "slideshowitems",
  initialState,
  reducers: {
    updateSlideshowItemOnBid: (state, action) => {
      if (state.activeItem && state.activeItem.itemid === action.payload.itemid) {
        state.activeItem = {
          ...state.activeItem,
          lastbid: action.payload.bidamount,
          quantityavailable: action.payload.quantity,
          numberofbids: action.payload.numberOfbids,
        }
      }
      const newItems = state.auctionitems.map((item: SSAuctionItem) => {
        if (item.itemid === action.payload.itemid) {
          return {
            ...item,
            lastbid: action.payload.bidamount,
            quantityavailable: action.payload.quantity,
            numberofbids: action.payload.numberOfbids,
          }
        } else {
          return item
        }
      })
      state.auctionitems = newItems
    },
    updateActiveItem: (state, action) => {
      state.activeItem = action.payload
    },
    updateActiveAuctionItems: (state, action) => {
      state.activeAuctionItems = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      "slideshowitems/fetchSlideshowItemsData/pending",
      (state, action) => {
        state.status = "loading"
      }
    )
    builder.addCase(
      "slideshowitems/fetchSlideshowItemsData/fulfilled",
      (state , action: any) => {
        state.auctionitems = action.payload.auctionitems
        state.activeItem = (action.payload.auctionitems && action.payload.auctionitems.length > 0 ? action.payload.auctionitems[0] : null)
        state.auctioneventid = action.payload.auctioneventid
        state.auctionsiteid = action.payload.auctionsiteid
        state.token = action.payload.token
        state.message = action.payload.message
        state.status = "succeeded"
      }
    )
    builder.addCase(
      "slideshowitems/fetchSlideshowItemsData/rejected",
      (state, action: any) => {
        state.status = "failed"
        state.error = action.error.message
      }
    )
  }
})

export const { updateSlideshowItemOnBid, updateActiveItem, updateActiveAuctionItems } = slice.actions
export default slice.reducer
