import styled from "@emotion/styled"
import { mq } from "../../constants"

type Props = {
  width?: string
  justifyContent?: string
  flexWrap?: string
  alignItems?: string
  padding?: string
  margin?: string
  border?: string
  borderRadius?: string
  height?: string
  backgroundColor?: string
}
/** Move media queries may be to components ?  */

const FlexRow = styled.div<Props>`
  display: flex;
  width: ${(props) => props.width || "100%"};
  justify-content: ${(props) => props.justifyContent || "center"};
  flex-wrap: ${(props) => props.flexWrap || "wrap"};
  align-items: ${(props) => props.alignItems || "unset"};
  height: ${(props) => props.height || "unset"};
  padding: ${(props) => props.padding || "unset"};
  background-color: ${(props) => props.backgroundColor || "unset"};
`
export default FlexRow
