import styled from "@emotion/styled"
import { Console } from "console"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"
import waveBluePng from "../../assets/img/svg/wave-blue.png";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const AccountContact: React.FC = () => {
    const eventData = useSelector((state: any) => state.event)

    return (
        <PageBodyContainer>
            <EventNameContainer>
                <Text
                    fontSize="35px"
                    fontWeight="bold"
                    textAlign="center"
                    padding="24px 0 16px 0"
                >
                  Contact Us
                </Text>
            </EventNameContainer>
            <WaveContainer></WaveContainer>
            
            <Text textAlign="center" margin={'10px'}>
                <div dangerouslySetInnerHTML={{ __html: eventData.contactus }} />
            </Text>
        </PageBodyContainer>
    )
}

export default AccountContact
