import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'signalr',
    initialState: {
        connected: false,
        disconnected: true,
        reconnecting: false
    },
    reducers: {
        connected: state => {
            let newState = state
            return Object.assign(newState, { connected: true, disconnected: false, reconnecting: false })
        },
        disconnected: state => {
            let newState = state
            return Object.assign(newState, { connected: false, disconnected: true, reconnecting: false })
        },
        reconnecting: state => {
            let newState = state
            return Object.assign(newState, { connected: false, disconnected: true, reconnecting: true })
        }
    }
})

export const { connected, disconnected, reconnecting } = slice.actions
export default slice.reducer