import styled from "@emotion/styled"
import React from "react"
import Img from "./Img"
import LogoContainer from "./LogoContainer"
import LogoWrapper from "./LogoWrapper"

type Props = {
  eventResponse: any
  isEventLoading: boolean
}

const LoginWelcome: React.FC<Props> = (props: Props) => {
  const {
    eventResponse,
    isEventLoading
  } = props

  return (
    <>
      {!isEventLoading && (
        <>
          <LogoWrapper>
            <LogoContainer>
              <Img
                alt="logo"
                src={eventResponse.logourl}
              />
            </LogoContainer>
          </LogoWrapper>
        </>
      )}
    </>
  )
}

export default LoginWelcome
