import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../util/api/fetchData"
import { act } from "react-dom/test-utils"

type PrivacyPolicyState = {
  privacypolicyData: any
  status: "idle" | "loading" | "succeeded" | "failed"
  error: any
  eventcode: any
  auctioncode: any
}

const initialState: PrivacyPolicyState = {
  privacypolicyData: "",
  status: "idle",
  error: null,
  eventcode: null,
  auctioncode: null
}


export const fetchPrivacyPolicy = createAsyncThunk(
  "privacyPolicy/fetchPrivacyPolicy",
    async ({ url, eventcode, auctioncode }: { url: string; eventcode: string, auctioncode: string }) => {
    const response = await client.post(url, {eventcode: eventcode, auctioncode: auctioncode})
    const parsedData = JSON.parse(response.jsonString)
    console.log(parsedData)
    return parsedData
  }
)

export const slice = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {
    setPrivacyEventCode: (state, action) => {
      state.eventcode = action.payload
    },
    setPrivacyAuctionCode: (state, action) => {
      state.auctioncode = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase("privacyPolicy/fetchPrivacyPolicy/pending", (state, action) => {})
    builder.addCase("privacyPolicy/fetchPrivacyPolicy/fulfilled", (state, action: any) => {
      state.privacypolicyData = action.payload.privacypolicy;
    })
    builder.addCase("privacyPolicy/fetchPrivacyPolicy/rejected", (state, action: any) => {})
  }
})

export const { setPrivacyEventCode, setPrivacyAuctionCode } = slice.actions
export default slice.reducer
