import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"
import { useNavigate, useParams } from "react-router-dom";
import {IonButton} from "@ionic/react";
import {fetchPrivacyPolicy} from "../../redux/slices/privacyPolicySlice";
import {getEndPoint, PRIVACY_POLICY} from "../../util/api/enpoints";

interface Params {
    eventcode: string
}

const PrivacyPolicy: React.FC = () => {
    const { eventcode } = useParams()
    const domainName =  window.location.hostname
    const domainNames = domainName.split('.')
    const auctioncode = (domainNames && domainNames.length > 0) ? domainNames[0] : 'app'

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const privacyPolicyState = useSelector((state: any) => state.privacypolicy)
    const [policyData, setPolicyData] = useState<string | undefined>();

    const clickGoBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        dispatch(fetchPrivacyPolicy({url: getEndPoint(PRIVACY_POLICY), eventcode: eventcode, auctioncode: auctioncode}))
    }, [])

    useEffect(() => {
        setPolicyData(privacyPolicyState.privacypolicyData)
    }, [privacyPolicyState])
    
    return (
        <PageBodyContainer alignItems="center" justifyContent="center">
          <Text textAlign="center">
            <div dangerouslySetInnerHTML={{ __html: policyData }} />
          </Text>
          <Text important fontSize="15px" onClick={clickGoBack}>
            <IonButton fill="clear" onClick={clickGoBack}>Go Back</IonButton>
          </Text>
        </PageBodyContainer>
    )
}

export default PrivacyPolicy