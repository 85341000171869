import styled from "@emotion/styled";

import Img from "../../components/Header/Img";
import { mq } from "../../constants";
import FlexCol from "../CommonStyles/FlexCol";
import { UserBidStatus } from "./Index";

export const FlexColAuction = styled(FlexCol)`
  ${mq[0]} {
    margin: 10px;
  }
  ${mq[1]} {
    margin: 10px;
  }
`;
export const Input = styled("input")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`;

export const ImgWelcome = styled(Img)`
  max-width: unset;
  max-height: unset;
  object-fit: cover;
  object-position: center;
`;

export const FilterBox = styled.div`
  position: absolute;
  padding: 0.5rem;
  background-color: white;
  right: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  z-index: 100;
  overflow-y: auto;
  transform: translateX(
    ${(props: { isOpen: boolean }) => (props.isOpen ? 0 : "100%")}
  );
  transition: transform 250ms ease-in-out;
  overflow: auto;
`;

export const UL = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 5px;
`;

export const Span = styled.span`
  font-size: 14px;
  font-weight: 400;
  padding-left: 5px;
`;

export const AttributeLI = styled.li`
  display: flex;
  height: 2.5rem;
  border-bottom: 1px solid #00000040;
  align-items: center;
`;

export const AttributeLabel = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
  align-items: center;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 7px;
  left: 5px;
  background: none;
  outline: none;
  font-size: 14px;
  color: ${(props) => props.theme.colors.important};
  text-decoration: underline;
  padding: 3px;
  display: flex;
  align-items: center;
`;

export const SlideOutTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  position: relative;
`;

export const SortButton = styled.button`
  width: 100%;
  background: #e1e1e1;
  height: 2.5rem;
  margin: 0.5rem 0;
  padding: 0 1rem;
  border-radius: 3px;
  color: black;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
`;

export const HeaderButton = styled.button`
  width: 44px;
  height: 44px;
  padding: 0;
  background-color: ${(props: any) => props.color};
  border-radius: 10px;
  box-shadow: 0px 2px 5px #00000080;
`;

export const SearchBarContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  background-color: #b6d9ee;
  height: 66px;
  width: 100%;
  align-items: center;
  padding: 0 12px;
  z-index: 99;
  top: 0px;
`;

type HeartIconProp = {
  active: boolean;
  inset?: string;
  right?: string;
  top?: string;
  position?: string;
  width?: string;
  height?: string;
  buyitnow?: boolean;
};
export const HeartIconButton = styled.button`
  position: ${(props: HeartIconProp) => props.position || "absolute"};
  top: ${(props: HeartIconProp) => props.top || props.inset || "0"};
  right: ${(props: HeartIconProp) => props.right || props.inset || "0"};
  width: ${(props: HeartIconProp) => props.width || "36px"};
  height: ${(props: HeartIconProp) => props.height || "36px"};
  opacity: ${(props: HeartIconProp) => props.buyitnow ? 0.10 : null};
  border-radius: 50%;
  background: ${(props: HeartIconProp) =>
    props.active ? "transparent" : "white"};
  box-shadow: ${(props: HeartIconProp) =>
    props.active ? "none" : "0px 2px 5px #00000026"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;
export const IconBackground = styled.div`
  position: ${(props: any) => props.position || "absolute"};
  top: ${(props: any) => props.inset || "0"};
  right: ${(props: any) => props.inset || "0"};
  width: ${(props: any) => props.width || "28px"};
  height: ${(props: any) => props.height || "28px"};
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 5px #00000026;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const ErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.important};
  padding: 1rem;
`;

type ListItemRowProps = {
  status: UserBidStatus;
  guest: boolean
};
type ListItemHeaderRowProps = {
  guest: boolean
};
export const ListItemRow = styled.div`
  display: grid;
  /* place-content: center; */
  align-items: center;
  grid-template-columns: ${(props: ListItemRowProps) => props.guest ? '1fr 6rem 4.5rem 3.5rem' : '2rem 1fr 6rem 4.5rem 3.5rem'};
  /* padding: 0.75rem 0.5rem; */
  grid-column-gap: 0.4rem;
  border-bottom: 1px solid #00000020;
  border: ${(props: ListItemRowProps) =>
    props.status === "winner" ? "2px solid #1CD182" : ""};
  background-color: ${(props: ListItemRowProps) =>
    props.status === "winner" ? "#D1F9D9" : "transparent"};
  border-radius: ${(props: ListItemRowProps) =>
    props.status === "winner" ? "6px" : "0px"};
  & > div {
    width: 100%;
    height: 100%;
    padding: 0.75rem 0rem;
    display: grid;
    align-items: center;
  }
`;

export const ListItemHeader = styled.div`
  display: grid;
  place-content: center;
  align-items: center;
  grid-template-columns: ${(props: ListItemHeaderRowProps) => props.guest ? '1fr 6rem 4.5rem 3.5rem' : '2rem 1fr 6rem 4.5rem 3.5rem'};
  grid-column-gap: 0.4rem;
  border-bottom: 1px solid #00000020;
  padding: 0 0.25rem;

  & > div {
    width: 100%;
    height: 100%;
    padding: 0.75rem 0rem;
    display: grid;
    align-items: center;
  }
`;

type LastBidContainerProps = {
  status: UserBidStatus;
};

export const LastBidContainer = styled.div`
  border-radius: 16px;

  justify-self: start;
  position: relative;
  z-index: 1;
  /* padding: 3px; */
  ::before {
    content: "";
    border: ${(props: LastBidContainerProps) =>
      props.status === "losingBidder" ? "1px solid #ff6f00" : ""};
    background: ${(props: LastBidContainerProps) =>
      props.status === "highBidder" ? "#1CD182" : ""};
    border-radius: 16px;
    z-index: -1;
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -8px;
    right: -8px;
  }
`;

export const ListIconContainer = styled.div`
  background-color: ${(props: ListItemRowProps) =>
    props.status === "winner" ? "#1CD182" : "transparent"};
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

export const ListViewGrid = styled.div`
  display: grid;
`;
