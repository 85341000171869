import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";

import createSignalRMiddleware from "./middleware/signalr-middleware";
import auctionItemDetailsSlice from "./slices/auctionItemDetailsSlice";
import auctionItemsReducer from "./slices/auctionItemsSlice";
import bidHistorySlice from "./slices/bidHistorySlice";
import cartSlice from "./slices/cartSlice";
import debugReducer from "./slices/debugSlice";
import eventReducer from "./slices/eventSlice";
import messageReducer from "./slices/messageSlice";
import privacyPolicyReducer from "./slices/privacyPolicySlice";
import signalrReducer from "./slices/signalrSlice";
import userReducer from "./slices/userSlice";
import notificationReducer from "./slices/notificationSlice";
import ordersSlice from "./slices/ordersSlice";
import signalRItemsSlice from "./slices/SignalRItemsSlice";
import slideShowItemSlice from "./slices/slideShowItemSlice";


const middlewares = getDefaultMiddleware();

const rootReducer = combineReducers({
  signalr: signalrReducer,
  responseMessage: messageReducer,
  event: eventReducer,
  debugState: debugReducer,
  auctionItems: auctionItemsReducer,
  auctionItemDetails: auctionItemDetailsSlice,
  bidHistory: bidHistorySlice,
  user: userReducer,
  cart: cartSlice,
  orders: ordersSlice,
  privacypolicy: privacyPolicyReducer,
  notification: notificationReducer,
  signalRItems: signalRItemsSlice,
  slideshowItems: slideShowItemSlice,
});

export default configureStore({
  reducer: rootReducer,
  middleware: middlewares.concat(createSignalRMiddleware),
});

export type AppState = ReturnType<typeof rootReducer>;
