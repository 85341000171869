import styled from "@emotion/styled"

type Text = {
  important?: boolean
  fontSize?: number | string
  textAlign?: string
  fontWeight?: string | number
  padding?: string | any
  color?: string
  borderRadius?: string
  margin?: string
  backgroundColor?: string
  decoration?: string
  width?: string
  height?: string
  inlineSize?: string
  flexWrap?: string
  maxWidth?: string
}

const Text = styled.div<Text>`
  color: ${(props) =>
    props.important
      ? props.theme.colors.important
      : props.color
      ? props.color
      : props.theme.colors.primary};
  font-size: ${(props) => props.fontSize || "20px"};
  width: ${(props) => props.width || "unset"};
  flex-wrap: ${(props) => props.flexWrap || "unset"};
  height: ${(props) => props.height || "unset"};
  text-align: ${(props) => props.textAlign || "none"};
  inline-size: ${(props) => props.inlineSize || "unset"};
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: ${(props) => props.maxWidth || "unset"};
  font-weight: ${(props) => props.fontWeight || 400};
  padding: ${(props) => props.padding || "unset"};
  border-radius: ${(props) => props.borderRadius || "unset"};
  background-color: ${(props) => props.backgroundColor || "unset"};
  margin: ${(props) => props.margin || "unset"};
  text-decoration: ${(props) => props.decoration || ""};
`

export default Text
