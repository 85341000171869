import React, {useEffect, useState} from "react"
import Menu from "../Menu/Index"
import Img from "./Img"
import Text from "../../pages/CommonStyles/Text"
import {IonBadge, IonButton, IonButtons, IonFab, IonFabButton, IonIcon, IonTitle, IonToolbar, IonItem} from "@ionic/react";

import {cartOutline} from "ionicons/icons";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import IconBack from "../Icons/IconBack";
import { logInOutline, logOutOutline } from "ionicons/icons";
import {menuSharp, arrowBack} from "ionicons/icons";
import styled from "@emotion/styled";
import iconBack from "../Icons/IconBack";
import jwt_decode from "jwt-decode";
import {
    AUCTION_ITEM_DETAIL_ENDPOINT,
    AUCTION_ITEMS_ENDPOINT,
    CART_ENDPOINT,
    getEndPoint
} from "../../util/api/enpoints";
import {fetchEventData, setAuctionCode, setEventClosing, setEventCode} from "../../redux/slices/eventSlice";
import {fetchAuctionItemsData} from "../../redux/slices/auctionItemsSlice";
import {fetchAuctionItemDetailsData} from "../../redux/slices/auctionItemDetailsSlice";
import {fetchCartData} from "../../redux/slices/cartSlice";
import {updateStateOnLogout} from "../../redux/slices/userSlice";
import {DateTime} from "luxon";
// import Badge from '@mui/material/Badge';

type Props = {
    eventResponse: any
    includeBack?: boolean
    backPath?: string
    itemid?: any
}

interface Params {
    eventcode: string
}

const AuctionItemsHeader: React.FC<Props> = ({ eventResponse, includeBack, backPath, itemid}) => {
    const { eventcode } = useParams()
    const cartState = useSelector((state: any) => state.cart)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const goBack = () => {
        navigate(backPath);
    }

    const goToCart = () => {
        navigate("/" + eventcode + "/Cart")
    }

    const logout = () => {
        dispatch(updateStateOnLogout())
        localStorage.removeItem("token")
        dispatch({
            type: "DISCONNECT_SIGNALR"
        })
        navigate('/' + eventcode + '/login')
    }

    const eventData = useSelector((state: any) => state.event)
    const userInfo = useSelector((state: any) => state.user)
    const [days, setDays] = useState<number | undefined>(undefined)
    const [hours, setHours] = useState<number | undefined>(undefined)
    const [mins, setMins] = useState<string | undefined>(undefined)
    const [seconds, setSeconds] = useState<string | undefined>(undefined)
    const [message, setMessage] = useState<string | undefined>(undefined)
    const [displayCountdown, setDisplayCountdown] = useState<boolean | undefined>(undefined)
    const [displayBiddingClosed, setDisplayBiddingClsoed] = useState<boolean | undefined>(undefined)
    const [displayLogo, setdisplayLogo] = useState<boolean | undefined>(undefined)
    const accountStatus = useSelector((state: any) => state.user.accountstatus)
    const authorizeBidder = useSelector((state: any) => state.user.authorizebidder)

    // const eventBiddingClosed = eventData.eventbiddingclosed

    var local = DateTime.local()
    const centralBiddingClosed = DateTime.fromISO(eventData.eventbiddingclosedcst, {zone: 'America/Chicago'});
    const localDateBiddingClosed = centralBiddingClosed.setZone(local.zoneName)
    const localDateBiddingClosedString = localDateBiddingClosed.toString()
    const [biddingStatus, setBiddingStatus] = useState<string>('open')
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    useEffect(() => {
        const checkThen: any = moment(localDateBiddingClosedString)
        const checkNow: any = moment()
        const checkCalcSeconds = checkThen.diff(checkNow, 'seconds')
        const checkDays        = Math.floor(checkCalcSeconds/24/60/60);
        const checkHoursLeft   = Math.floor((checkCalcSeconds) - (checkDays*86400));
        const checkHours       = Math.floor(checkHoursLeft/3600);

        if (checkDays >= 0 && checkDays < 1 && checkHours < 1) {
            const interval = setInterval(() => {
                const then: any = moment(localDateBiddingClosedString)
                const now: any = moment()
                // tslint:disable-next-line

                const calcSeconds = then.diff(now, 'seconds')
                const days = Math.floor(calcSeconds / 24 / 60 / 60);
                const hoursLeft = Math.floor((calcSeconds) - (days * 86400));
                const hours = Math.floor(hoursLeft / 3600);
                const minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
                const minutes = Math.floor(minutesLeft / 60);
                const remainingSeconds = calcSeconds % 60;

                function pad(n) {
                    return (n < 10 ? "0" + n : n);
                }

                console.log(pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds))

                setHours(pad(hours))
                setDays(pad(days))
                setMins(pad(minutes))
                setSeconds(pad(remainingSeconds))

                // Countdown
                if (days >= 0 && days < 1 && hours < 1) {
                    setDisplayBiddingClsoed(false)
                    setdisplayLogo(false)
                    setDisplayCountdown(true)
                    if (minutes > 0) {
                        setMessage("Bidding Closes in " + pad(minutes) + ":" + pad(remainingSeconds))
                    } else {
                        setMessage("Bidding Closes in " + pad(remainingSeconds))
                    }
                } else if (days < 0) {
                    setDisplayCountdown(false)
                    setdisplayLogo(false)
                    setDisplayCountdown(false)
                    setDisplayBiddingClsoed(true)

                    if (cartState.auctionswon?.length > 0) {
                        setMessage("Bidding Closed; Go to Checkout")
                    } else {
                        setMessage('')
                        setdisplayLogo(true)
                    }

                    getEventUpdate()
                    clearInterval(interval)
                } else {
                    setMessage('')
                    setdisplayLogo(true)
                }


            }, 1000)
            return () => clearInterval(interval)
        }
        else if (checkDays < 0) {
            setDisplayCountdown(false)
            setdisplayLogo(false)
            setDisplayCountdown(false)
            setDisplayBiddingClsoed(true)

            if (cartState.auctionswon?.length > 0) {
                setMessage("Bidding Closed. Go to Checkout")
            } else {
                setMessage('')
                setdisplayLogo(true)
            }
        } else {
            setMessage('')
            setdisplayLogo(true)
        }
    }, [localDateBiddingClosedString, cartState, eventData.eventclosing])

    const getEventUpdate = () => {
        let token = localStorage.getItem("token")
        if (token && token !== "undefined") {
            const decoded: any = jwt_decode(token)
            const url = getEndPoint('EVENT_ENDPOINT')
            const eventData = {
                url,
                eventcode: decoded.eventcode,
                auctioncode: decoded.auctioncode
            }
            dispatch(fetchEventData(eventData))
            dispatch(setEventCode(decoded.eventcode))
            dispatch(setAuctionCode(decoded.auctioncode))
            dispatch(fetchAuctionItemsData(getEndPoint(AUCTION_ITEMS_ENDPOINT)))
            dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
            if (itemid) {
                dispatch(
                    fetchAuctionItemDetailsData({
                        url: getEndPoint(AUCTION_ITEM_DETAIL_ENDPOINT),
                        itemId: itemid,
                    })
                )
            }
        }
    }

    return (
        <>
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>

            <IonToolbar color={'white'}>
                <IonButtons slot="start">
                {includeBack ?
                        <IonButton id="notification-button" ion-button fill="clear" onClick={() => {goBack()}}>
                            <IonIcon
                                // slot="icon-only"
                                icon={arrowBack}
                                style={{width: 44, height: 44, color: "#D2222D"}}
                            ></IonIcon>
                        </IonButton>
                    :
                        <IonButton id="notification-button" ion-button fill="clear" onClick={() => { setMenuOpen(!menuOpen) }}>
                            <IonIcon
                                // slot="icon-only"
                                icon={menuSharp}
                                style={{width: 44, height: 44, color: "#D2222D"}}
                            >
                            </IonIcon>
                        </IonButton>
                }
                </IonButtons>

                <IonTitle className="ion-wrap">
                    {authorizeBidder && displayCountdown &&
                            <Text
                                fontSize="16px"
                                important
                                fontWeight="bold"
                                textAlign="center"
                                flexWrap={'wrap'}
                            >
                                {message}
                            </Text>
                    }
                    {(authorizeBidder && displayBiddingClosed && message?.length > 0) &&
                        // <IonItem class="ion-text-wrap" slot="end">
                        <IonButton expand="full" className="ion-text-center ion-text-wrap ion-align-items-center ion-wrap" id="notification-button" ion-button fill="clear" onClick={goToCart}>
                        <Text
                            fontSize="16px"
                            important
                            fontWeight="bold"
                            textAlign="center"
                            flexWrap={'wrap'}
                        >
                            {message}
                        </Text>
                        </IonButton>
                    }

                    {(!authorizeBidder && accountStatus !== 'guest') ?
                            <IonButton expand="full" className="ion-text-center ion-text-wrap ion-align-items-center ion-wrap" id="notification-button" ion-button fill="clear">
                                <Text
                                    fontSize="16px"
                                    important
                                    fontWeight="bold"
                                    textAlign="center"
                                    flexWrap={'wrap'}
                                >
                                    Not authorized to bid. Contact the event organizer.
                                </Text>
                            </IonButton>
                        :
                            <Img
                                alt="logo"
                                hidden={!displayLogo}
                                src={eventResponse.logourl}
                            />
                    }
                </IonTitle>

                {(cartState?.auctionswon?.length === 0 && displayBiddingClosed) &&
                    <IonItem class="ion-text-wrap" slot="end">
                        <Text
                            fontSize="14px"
                            important
                            fontWeight="bold"
                            textAlign={'end'}
                            maxWidth={'100px'}
                            flexWrap={'wrap'}
                        >
                            BIDDING CLOSED
                        </Text>
                    </IonItem>
                }

                {((cartState?.auctionswon?.length > 0)) &&
                    <IonButtons slot="end">
                        <IonButton id="notification-button" ion-button fill="clear" onClick={goToCart}>
                            <IonIcon
                                // slot="icon-only"
                                icon={cartOutline}
                                style={{width: 44, height: 44, color: "#D2222D"}}
                            >
                            </IonIcon>
                            {cartState?.auctionswon?.length > 0 &&
                                <IonBadge id="notifications-badge" style={{
                                    color: '#0a0a0a',
                                    background: '#FFFFFF00',
                                    position: 'absolute',
                                    width: 22,
                                    height: 18,
                                    right: 10,
                                    top: 4
                                }}>{cartState?.auctionswon?.length > 0 ? cartState?.auctionswon?.length : 0}</IonBadge>
                            }
                        </IonButton>
                    </IonButtons>
                }
                {/*{accountStatus !== 'guest' &&*/}
                {/*    <>*/}
                {/*        <IonButtons slot="end">*/}
                {/*            <IonButton id="notification-button" ion-button fill="clear" onClick={logout}>*/}
                {/*                <IonIcon*/}
                {/*                    icon={logOutOutline}*/}
                {/*                    style={{width: 44, height: 44, color: "#D2222D"}}*/}
                {/*                >*/}
                {/*                </IonIcon>*/}
                {/*            </IonButton>*/}
                {/*        </IonButtons>*/}
                {/*    </>*/}
                {/*}*/}
                {accountStatus === 'guest' &&
                    <>
                        <IonButtons slot="end">
                            <IonButton id="notification-button" ion-button fill="clear" onClick={logout}>
                                <IonIcon
                                    icon={logInOutline}
                                    style={{width: 44, height: 44, color: "#D2222D"}}
                                >
                                </IonIcon>
                            </IonButton>
                        </IonButtons>
                    </>
                }
            </IonToolbar>
        </>
  )
}

export default AuctionItemsHeader



