import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import styled from "@emotion/styled";

const Container = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 50px;
  padding: 5px;
  background-color: white;
  text-align: center;
  vertical-align: middle;
`;

const SponsorImages: React.FC = () => {
  const eventData = useSelector((state: any) => state.event);
  const [sponsorImage, setSponsorImage] = useState<string | undefined>(
    undefined
  );
  const sponsorImages = eventData.sponsorurl;

  useEffect(() => {
    // Init to first image
    let imageIndex = 0;
    setSponsorImage(sponsorImages[imageIndex]);
    const imageInterval = setInterval(() => {
      if (imageIndex < sponsorImages.length - 1) {
        imageIndex += 1;
      } else {
        imageIndex = 0;
      }
      setSponsorImage(sponsorImages[imageIndex]);
    }, 5000);
    return () => clearInterval(imageInterval);
  }, [sponsorImages]);

  return (
      <img
        style={{ maxHeight: 90, minHeight: 80, verticalAlign: "middle", backgroundColor: '#f2f2f2' }}
        src={sponsorImage}
      ></img>

  );
};

export default SponsorImages;
