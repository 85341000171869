import { logoUsd } from "ionicons/icons";
import React from "react";

import styled from "@emotion/styled";
import { IonIcon } from "@ionic/react";

type Container = {
  scale: number;
};
const Container = styled.div`
  background: #1cd182;
  border-radius: 50%;
  width: ${(props: Container) => props.scale * 17.6}px;
  height: ${(props: Container) => props.scale * 17.6}px;
  display: flex;
  place-content: center;
  place-items: center;
  position: relative;

  ::before {
    position: absolute;
    content: "";
    width: ${(props: Container) => props.scale * 9}px;
    height: ${(props: Container) => props.scale * 9}px;
    border-top: 2px solid #1cd182;
    border-left: 2px solid #1cd182;
    top: ${(props: Container) => props.scale * -6}px;
    /* right: 3.35px; */
    right: 22%;

    transform: rotate(45deg);
  }
`;

type Props = {
  scale?: number;
};

export const IconWinningBidder = ({ scale = 1 }: Props) => {
  return (
    <Container scale={scale}>
      <IonIcon
        icon={logoUsd}
        slot="icon-only"
        color="dark"
        style={{ width: scale * 14.4, height: scale * 14.4 }}
      />
    </Container>
  );
};
