import React from "react";

export const IconLosingBid = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill={"#ff6f00"}
      {...props}
    >
      <path d="M28.5,5.33,27.06,6.77A10.86,10.86,0,0,1,29,13,11,11,0,0,1,18,24a10.86,10.86,0,0,1-6.22-1.95l-1.44,1.44A13,13,0,0,0,28.5,5.33ZM18,2a10.88,10.88,0,0,1,6.23,1.94l1.43-1.43A13,13,0,0,0,7.5,20.67l1.44-1.44A11,11,0,0,1,18,2ZM15.22,13a3.47,3.47,0,0,1-2-3.18c0-1.93,1.53-3.19,3.44-3.61V4h2.95V6.19a4,4,0,0,1,1.6.81l-1.4,1.4a2.72,2.72,0,0,0-1.72-.46c-1.66,0-2.65.76-2.65,1.85,0,.67.36,1.18,1.36,1.62ZM23,16.3c0,2.05-1.52,3.18-3.44,3.55V22H16.62V19.83a4.09,4.09,0,0,1-1.88-.74l1.42-1.42a3.75,3.75,0,0,0,1.93.43c2.17,0,2.65-1.1,2.65-1.79a2,2,0,0,0-1.36-1.86L21,12.88a3.71,3.71,0,0,1,2,3.42ZM9.51,22.9,8.1,21.49,26.49,3.1,27.9,4.51Zm19.23,2.43a1,1,0,0,1-.07,1.41l-10,9a1,1,0,0,1-1.34,0l-10-9a1,1,0,0,1,1.34-1.48L18,33.65l9.33-8.39a.994.994,0,0,1,1.41.07Z" />
    </svg>
  );
};
