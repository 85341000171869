import React from "react";

import styled from "@emotion/styled";

type CheckboxProps = {
  checked: boolean;
  height?: string;
  width?: string;
  borderRadius?: string;
  checkedBackgroundColor: string;
  uncheckedBackgroundColor: string;
};

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const StyledCheckbox = styled.div<CheckboxProps>`
  display: inline-block;
  width: ${(props) => props.width || "16px"};
  height: ${(props) => props.height || "16px"};
  background: ${(props) =>
    props.checked
      ? props.checkedBackgroundColor
      : props.uncheckedBackgroundColor};
  border-radius: ${(props) => props.borderRadius || "3px"};
  transition: all 150ms;
  border: ${(props) =>
    props.borderRadius && !props.checked
      ? `2px solid ${props.checkedBackgroundColor}`
      : "none"};
`;

const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const Checkbox = ({ className, checked, ...props }: any) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} type="checkbox" {...props} />
    <StyledCheckbox
      checked={checked}
      height={props.height}
      width={props.width}
      borderRadius={props.borderRadius}
      checkedBackgroundColor={props.checkedBackgroundColor}
      uncheckedBackgroundColor={props.uncheckedBackgroundColor}
    >
      <Icon viewBox="0 0 24 26" visibility={checked ? "visible" : "hidden"}>
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

const CheckBox: React.FC<{
  name: string;
  handleCheckboxChange: any;
  checked: boolean;
  filterType?: string;
  param: string;
  height?: string;
  width?: string;
  checkedBackgroundColor: string;
  uncheckedBackgroundColor: string;
  borderRadius?: string;
  id?: string | number;
  disabled?: boolean;
}> = ({ name, handleCheckboxChange, checked, param, disabled, ...props}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={handleCheckboxChange}
      name={param}
      disabled={disabled}
      {...props}
    />
  );
};

export default CheckBox;
