import { createSlice } from "@reduxjs/toolkit"

export const slice = createSlice({
  name: "responsemessage",
  initialState: {
    eventData: ""
  },
  reducers: {
    updateEventData: (state, action) => {
      state.eventData = action.payload
    }
  }
})

export const { updateEventData } = slice.actions
export default slice.reducer
