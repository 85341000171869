import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../util/api/fetchData"

type debugState = {
    payloadData:  any
}

const initialState: debugState = {
    payloadData: []
}

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
    return key in obj
}

export const slice = createSlice({
    name: "debugState",
    initialState,
    reducers: {
        setPayloadData: (state, action) => {
            console.log(action.payload)
            state.payloadData.push(action.payload)
        }
    },
    extraReducers: (builder) => {
    }
})

export const { setPayloadData } = slice.actions
export default slice.reducer
