import styled from "@emotion/styled"

type Props = {
  height?: number | string
  width?: number | string
  backgroundColor?: string
  color?: string
  opacity?: number | string
}

const Button = styled.button<Props>`
  align-self: center;
  opacity: ${(props) => props.opacity || 100.0};
  height: ${(props) => props.height || "60px"};
  border-radius: 24px;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.important};
  color: ${(props) => props.color || "white"};
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 10px 60px;
  width: ${(props) => props.width};
`

export default Button
