import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../util/api/fetchData"

type BidHistoryState = {
    message: string
    status: "idle" | "loading" | "succeeded" | "failed"
    error: any
    itemid: string
    history: any
}

const initialState: BidHistoryState = {
    message: "",
    status: "idle",
    error: null,
    itemid: "",
    history: null,
}

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
    return key in obj
}

export const fetchBidHistoryData = createAsyncThunk(
    "bidhistory/bidHistoryData",
    async (config: any ) => {

        const token = localStorage.getItem("token")
        const response = await client.post(
            config.url,
            {
                itemid: parseInt(config.itemId)
            },
            {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        )
        const parsedData = JSON.parse(response.jsonString)
        return parsedData
    }
)

export const slice = createSlice({
    name: "bidHistory",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(
            "bidhistory/bidHistoryData/pending",
            (state: BidHistoryState, action) => {
                state.status = "loading"
            }
        )
        builder.addCase(
            "bidhistory/bidHistoryData/fulfilled",
            (state: any, action: any) => {
                Object.keys(state).map((key) => {
                    if (key === 'status' || key === 'error')
                        return;
                    state[key] = '';
                })
                Object.keys(state).map((key) => {
                    if (key in action.payload) {
                        if (hasKey(state, key)) {
                            let value = action.payload[key]
                            state[key] = value
                        }

                        return
                    }
                })
                state.status = "succeeded"
            }
        )
        builder.addCase(
            "bidhistory/bidHistoryData/rejected",
            (state, action: any) => {
                state.status = "failed"
                state.error = action.error.message
            }
        )
    }
})

export default slice.reducer
