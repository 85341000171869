import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../util/api/fetchData"

type OrderState = {
  rownumber: number
  checkoutid: number
  itemid: number
  name: string
  description: string
  itemnum: string
  quantity: number
  amount: number
  location: string
  pickupinstructions: string
  status: string
}

type OrdersState = {
  status: "idle" | "loading" | "succeeded" | "failed"
  error: any
  orderitems: null | OrderState[]
}

const initialState: OrdersState = {
  status: "idle",
  error: "",
  orderitems: []
}

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

export const fetchOrdersData = createAsyncThunk(
  "orders/fetchOrdersData",
  async (url: string) => {
    const token = localStorage.getItem("token")
    const response = await client.get(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    const parsedData = JSON.parse(response.jsonString)
    return parsedData
  }
)


export const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetOrderStatus: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase("orders/fetchOrdersData/pending", (state, action) => {
      state.status = "loading"
    })
    builder.addCase("orders/fetchOrdersData/fulfilled", (state, action: any) => {
      state.status = "succeeded"
      console.log(action.payload.orderitems)
      Object.keys(state).map((key) => {
        if (key in action.payload) {
          if (hasKey(state, key)) {
            let value = action.payload[key]
            state[key] = value
          }
          return
        }
      })
    })
    builder.addCase("orders/fetchOrdersData/rejected", (state, action: any) => {
      state.status = "failed"
      state.error = action.error.message
    })
  }
})

export const {resetOrderStatus} = slice.actions
export default slice.reducer
