import styled from "@emotion/styled"
import "../Checkout/iframe.css"
import {IonCol, IonGrid, IonRefresher, IonRefresherContent, IonRow} from "@ionic/react"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import IconAdd from "../../components/Icons/IconAdd"
import IconRemove from "../../components/Icons/IconRemove"
import Text from "../CommonStyles/Text"
import { useSelector } from "react-redux"
import {
  fetchCartData,
  setInitialAuctionsWonChecked
} from "../../redux/slices/cartSlice"
import {AUCTION_ITEMS_ENDPOINT, CART_ENDPOINT, getEndPoint, ORDERS_ENDPOINT} from "../../util/api/enpoints"
import { fetchOrdersData } from "../../redux/slices/ordersSlice"
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import {useAuthRefresh} from "../../hooks/authenicationHook";
import {fetchAuctionItemsData} from "../../redux/slices/auctionItemsSlice";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0 5px;
`


const MyOrders = () => {
  useAuthRefresh();

  const elementScroll = document.getElementById("scrollBody")
  if (elementScroll) {
    elementScroll.scroll(0,0)
  }

  const dispatch = useDispatch()
  const [openItemId, setOpenItemId] = useState<null | number>(null)
  const cartState = useSelector((state: any) => state.cart)
  const ordersState = useSelector((state: any) => state.orders)
  const eventState = useSelector((state: any) => state.event)

  useEffect(() => {
    if (cartState.status === "idle") {
      dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
    }
  }, [dispatch, cartState.status])

  useEffect(() => {
    if (cartState.auctionswon && cartState.auctionswon.length) {
      if (cartState.auctionsWonChecked === null) {
        dispatch(setInitialAuctionsWonChecked())
      }
    }
  }, [cartState, dispatch])


  useEffect(() => {
    if (cartState.status === "idle") {
      dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
    }
  }, [dispatch, cartState.status])


  useEffect(() => {
    if (ordersState.status === "idle") {
      dispatch(fetchOrdersData(getEndPoint(ORDERS_ENDPOINT)))
    }
  }, [dispatch, ordersState.status])

  const openItem = (id: number) => {
    if (id === openItemId) {
      setOpenItemId(null)
    } else {
      setOpenItemId(id)
    }
  }
  // console.log(ordersState)


  interface RefresherEventDetail {
    complete(): void;
  }

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      // Any calls to load data go here
      if (ordersState.status === "idle") {
        dispatch(fetchOrdersData(getEndPoint(ORDERS_ENDPOINT)))
      }
      if (cartState.status === "idle") {
        dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
      }
      event.detail.complete();
    }, 1000);
  }

  return (
    <PageContainer>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent refreshingSpinner={'circular'} refreshingText={'Loading...'}></IonRefresherContent>
      </IonRefresher>
        <EventNameContainer>
          <Text
              fontSize="35px"
              fontWeight="bold"
              textAlign="center"
              padding="24px 0 16px 0"
          >
            My Orders
          </Text>
        </EventNameContainer>
        <WaveContainer></WaveContainer>
        {(ordersState?.orderitems?.length < 1 && ordersState?.status === 'success') && (
            <>
              <Text textAlign="center" padding="1rem">You have no completed purchases.</Text>
              <Text textAlign="center" padding="1rem">After checkout, your purchase history will be displayed here.</Text>
            </>
        )}

        {ordersState.orderitems.map((item: any) => (
        <React.Fragment key={item.checkoutid}>
          <IonGrid
            style={{ border: "1px solid #dedede", borderRadius: "6px" }}
            onClick={() => openItem(item.checkoutid)}
          >
            <IonRow className="ion-align-items-center">
              <IonCol>
                <Text fontSize="16px" important fontWeight="800">
                  {item.name}
                </Text>
              </IonCol>
              {/*<IonCol className="ion-align-items-end" size="auto">*/}
              {/*  {openItemId !== item.checkoutid && (*/}
              {/*    <IconAdd fill="#d2222d"></IconAdd>*/}
              {/*  )}*/}
              {/*  {openItemId === item.checkoutid && (*/}
              {/*    <IconRemove fill="#d2222d"></IconRemove>*/}
              {/*  )}*/}
              {/*</IonCol>*/}
            </IonRow>
            {/*{openItemId === item.checkoutid && (*/}
              <>
                <IonRow >
                  <IonCol size="12">
                    <Text fontSize="12px" color="#666666" fontWeight="800">
                      Pickup Instructions
                    </Text>
                  </IonCol>
                  <IonCol size="12">
                    <Text fontSize="14px" color="#666666">
                      {item.pickupinstructions}
                    </Text>
                  </IonCol>
                </IonRow>

                {(item.itemnum && item.itemnum.length > 0) && (
                    <IonRow>
                      <IonCol size="3">
                        <Text fontSize="12px" color="#a2a2a2">
                          Item Number
                        </Text>
                      </IonCol>
                      <IonCol size="9">
                        <Text fontSize="14px" color="#666666">
                          {item.itemnum}
                        </Text>
                      </IonCol>
                    </IonRow>
                )}

                <IonRow>
                  <IonCol size="3">
                    <Text fontSize="12px" color="#a2a2a2">
                      Quantity
                    </Text>
                  </IonCol>
                  <IonCol size="9">
                    <Text fontSize="14px" color="#666666">
                      {item.quantity}
                    </Text>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="3">
                    <Text fontSize="12px" color="#a2a2a2">
                      Price
                    </Text>
                  </IonCol>
                  <IonCol size="9">
                    <Text fontSize="14px" color="#666666">
                      ${item.amount}
                    </Text>
                  </IonCol>
                </IonRow>

                {(item.location && item.location.length > 0) && (
                    <IonRow>
                      <IonCol size="3">
                        <Text fontSize="12px" color="#a2a2a2">
                          Item Location
                        </Text>
                      </IonCol>
                      <IonCol size="9">
                        <Text fontSize="14px" color="#666666">
                          {item.location}
                        </Text>
                      </IonCol>
                    </IonRow>
                )}

                <IonRow className="ion-align-items-center ion-justify-content-end">
                  <Text fontSize="16px" important fontWeight="800">
                    <Link to={'/' + eventState.eventcode + `/auction-items/${item.itemid}`}>
                      View Item's Detail Page
                    </Link>
                  </Text>
                </IonRow>
              </>
            {/*)}*/}
          </IonGrid>
        </React.Fragment>
      ))}
      <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
        <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
          AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
          <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventState.eventcode + "/privacy-policy"}>Privacy Policy 432</Link></a>
                  </span>
        </div>
      </div>
    </PageContainer>
  )
}

export default MyOrders
