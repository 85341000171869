import React from "react"
import {Route, Routes} from "react-router-dom"
import SponsorImages from "./SponsorImages"
import {useSelector} from "react-redux";
import {IonFooter, IonToolbar} from "@ionic/react";

const Footer: React.FC = () => {
  const eventData = useSelector((state: any) => state.event)
  return (
      <>
          {(eventData && eventData?.sponsorurl && eventData?.sponsorurl?.length > 0) &&
              <Routes>
                  <Route path={"/" + eventData.eventcode + "/welcome"}
                         element={
                             <IonFooter color={'primary'} class="ion-no-border">
                                 <IonToolbar color={'primary'}>
                                     <SponsorImages></SponsorImages>
                                 </IonToolbar>
                             </IonFooter>
                        }
                  />
                  <Route path={"/*"}
                         element={<></>}
                  />
              </Routes>
          }
      </>
  )
}

export default Footer
