import styled from "@emotion/styled";

import { mq } from "../../constants";

type NavProps = {
  open: boolean;
};

const NavMenuContainer = styled.div<NavProps>`
  position: absolute;
  top: ${(props) => props.theme.components.navBarHeight};
  left: 0;
  background: #ffffff;
  width: 100%;
  height: 100%;
  ${mq[2]} {
    width: ${(props) => (props.open ? "250px" : 0)};
  }
  transform: translateX(${(props) => (props.open ? 0 : "-100%")});
  transition-property: transform;
  transition-duration: 250ms;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  font-size: 16px;
  font-weight: 300;
`;

export default NavMenuContainer;
