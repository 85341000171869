import styled from "@emotion/styled";

import { UserBidStatus } from "../AuctionItems/Index";
import {keyframes} from "@emotion/react";

type BuyItNowProps = {
  open: boolean;
};

type AnimationProps = {
    play: boolean;
};

export const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props: { buyitnow: boolean }) =>
      props.buyitnow ? "#b0afaf" : null};
  overflow-y: auto;
`;

export const BuyItNowContainer = styled.div<BuyItNowProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  height: ${(props) => (props.open ? "300px" : 0)};
  transition: all 0.5s ease-out;
  z-index: 10000;
  //transition-duration: 8s;
  //-webkit-transition: 0.2s;
  //-moz-transition: 0.2s;
  //-o-transition: 0.2s;
  //transition: 0.2s;
  //transition-property: height;
  border-radius: 30px 30px 0 0;
`;

export const Select = styled("select")`
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  ::placeholder {
    font-size: 14px;
    padding-left: 4px;
  }
`;
export const Option = styled("option")`
  box-sizing: border-box;
  background-color: #0000000d;
  outline: none;
  border: none;
  ::placeholder {
    font-size: 14px;
    padding-left: 4px;
  }
`;

export const HighBidderTextContainer = styled.div`
  background: #a3f2b2;
  border-radius: 16px;
  padding: 0.2rem 0.5rem;
`;

export const LosingBidderTextContainer = styled.div`
  border-radius: 16px;
  border: 2px solid #ff6f00;
  padding: 0.2rem 0.5rem;
`;

export const WinnerIconContainer = styled.div`
  background: #a3f2b2;
  border-radius: 50%;
  padding: 0.5rem;
`;

export const IconContainer = styled("div")`
  grid-row: 1/4;
  width: 100%;
  height: 100%;
  background: ${(props: { status: UserBidStatus }) =>
    props.status === "winner" ? "#1CD182" : null};
  display: grid;
  place-items: center;
`;

// const bounce = keyframes`
//       from, 20%, 53%, 80%, to {
//         transform: translate3d(0,0,0);
//       }
//
//       40%, 43% {
//         transform: translate3d(0, -30px, 0);
//       }
//
//       70% {
//         transform: translate3d(0, -15px, 0);
//       }
//
//       90% {
//         transform: translate3d(0,-4px,0);
//       }
//     `


export const StatusTextContainer = styled.div<AnimationProps>`
  margin-left: 0.5rem;
  display: grid;
  justify-items: start;
  grid-gap: 0.25rem;
  //border: 2px solid #ff6f00;
`;

// export const AnimatedTextContainer = styled.div<AnimationProps>`
//   animation: ${bounce} 1s ease infinite;
//   // animation-iteration-count: 1;
//   animation-play-state: ${(props) => (props.play ? 'running' : 'paused')};
// `;