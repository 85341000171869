import React from "react"

const IconMinus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="#ffffff"
    >
      <path d="M0 10h24v4h-24z" />
    </svg>
  )
}

export default IconMinus
