import {getConfig} from "../../AppConfig";

const { apiUrl } = getConfig()
export const HOST = apiUrl
const EVENT_ENDPOINT = HOST + "/event/"
const LOGIN_URL = HOST + "/login/"
const GUEST_LOGIN_URL = HOST + "/guestlogin"
const JOIN_EMAIL = HOST + "/join"
export const USER_PROFILE = "/userprofile"
export const CREATE_ACCOUNT = "/create"
export const FORGOT_PASSWORD = "/forgotpassword"
export const PASSWORD_RESET = "/passwordreset"
export const PASSWORD_RESET_LINK = "/passwordresetlink"
export const RESET_PASSWORD = "/userpasswordreset"
export const PRIVACY_POLICY = "/privacypolicy"
export const SLIDESHOW = "/itemoverview"
export const AUCTION_ITEMS_ENDPOINT = "/auctionitems"
export const WATCH_LIST = "/watchlist"
export const AUCTION_ITEM_DETAIL_ENDPOINT = "/itemdetails"
export const CART_ENDPOINT = "/cart"
export const ORDERS_ENDPOINT = "/myorders"
export const BID_HISTORY = "/bidhistory"
export const SUBMIT_BID = "/submitbid"
export const SUBMIT_MAX_BID = "/maxbid"
export const VERIFY_EMAIL = "/verify"
export const CHECKOUT_CART = "/checkout"

const joinCustomParams = (customParams: Array<string>) => {
  return customParams.join("/")
}

export const getEndPoint = (
  type: string,
  customParams: Array<string> = [""]
) => {
  switch (type) {
    case "EVENT_ENDPOINT":
      return EVENT_ENDPOINT + joinCustomParams(customParams)
    case AUCTION_ITEMS_ENDPOINT:
      return HOST + AUCTION_ITEMS_ENDPOINT
    case AUCTION_ITEM_DETAIL_ENDPOINT:
      return HOST + AUCTION_ITEM_DETAIL_ENDPOINT
    case "LOGIN_URL":
      return LOGIN_URL + joinCustomParams(customParams)
    case "GUEST_LOGIN_URL":
      return GUEST_LOGIN_URL + joinCustomParams(customParams)
    case "JOIN_EMAIL":
      return JOIN_EMAIL
    case BID_HISTORY:
      return HOST + BID_HISTORY
    case CREATE_ACCOUNT:
      return HOST + CREATE_ACCOUNT
    case USER_PROFILE:
      return HOST + USER_PROFILE
    case FORGOT_PASSWORD:
      return HOST + FORGOT_PASSWORD
    case PASSWORD_RESET:
      return HOST + PASSWORD_RESET
    case PASSWORD_RESET_LINK:
      return HOST + PASSWORD_RESET_LINK
    case RESET_PASSWORD:
      return HOST + RESET_PASSWORD
    case CART_ENDPOINT:
      return HOST + CART_ENDPOINT
    case ORDERS_ENDPOINT:
      return HOST + ORDERS_ENDPOINT
    case PRIVACY_POLICY:
      return HOST + PRIVACY_POLICY
    case SLIDESHOW:
      return HOST + SLIDESHOW
    case SUBMIT_BID:
      return HOST + SUBMIT_BID
    case SUBMIT_MAX_BID:
      return HOST + SUBMIT_MAX_BID
    case VERIFY_EMAIL:
      return HOST + VERIFY_EMAIL
    case CHECKOUT_CART:
      return HOST + CHECKOUT_CART
      case WATCH_LIST:
      return HOST + WATCH_LIST
    default:
      return ""
  }
}
