import React, { useState } from "react"
import styled from "@emotion/styled"
import { IonToast } from "@ionic/react"
import Button from "../CommonStyles/Button"
import InputsContainer from "../CommonStyles/InputsContainer"
import Label from "../CommonStyles/Label"
import Text from "../CommonStyles/Text"
import {Link} from "react-router-dom";
import FlexRow from "../CommonStyles/FlexRow";
import {useSelector} from "react-redux";

const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const StepOne: React.FC<{
  formFields: {
    firstname: string
    lastname: string
    zip: number
  }
  createChangeHandler: any
  goToNextView: any
}> = (props) => {
  const [toastMsg, setToastMsg] = useState('');
  const eventData = useSelector((state: any) => state.event)

  const validateFields = (cb: Function) => (e) => {
    e.preventDefault()

    const { firstname, lastname, zip} = props.formFields
    if (!firstname) {
      return setToastMsg('First name is required.')
    } 
    if (!lastname) {
      return setToastMsg('Last name is required.')
    }
    if (!zip) {
        return setToastMsg('Zip code is required.')
    }
    cb(e)
  }

  return (
    <>
        <IonToast
            isOpen={Boolean(toastMsg)}
            onDidDismiss={() => setToastMsg('')}
            message={toastMsg}
            duration={3000}
            cssClass={'custom-toast'}
            color="danger"
        />
        <InputsContainer>
            <Label fontWeight={600} htmlFor="firstname">
              *First Name
            </Label>
            <Input
              type="text"
              id="firstname"
              autoFocus={true}
              placeholder="Enter your First Name here"
              name="firstname"
              value={props.formFields.firstname}
              onChange={props.createChangeHandler("firstname")}
            ></Input>
        </InputsContainer>
        <InputsContainer>
            <Label fontWeight={600} htmlFor="lastname">
              *Last Name
            </Label>
            <Input
              type="text"
              id="lastname"
              placeholder="Enter your Last Name here"
              name="lastname"
              value={props.formFields.lastname}
              onChange={props.createChangeHandler("lastname")}
            ></Input>
        </InputsContainer>
      {/*<InputsContainer>*/}
      {/*  <Label fontWeight={600} htmlFor="cellPhone">*/}
      {/*    Cell Phone*/}
      {/*  </Label>*/}
      {/*  <NumberFormat*/}
      {/*    type="tel"*/}
      {/*    id="cellphone"*/}
      {/*    placeholder="Enter your Cell Phone"*/}
      {/*    name="cellphone"*/}
      {/*    value={props.formFields.cellphone}*/}
      {/*    onChange={props.createChangeHandler("cellphone")}*/}
      {/*    format="(###) ###-####" mask="_" */}
      {/*    customInput={Input}/>*/}

      {/*</InputsContainer>*/}
        <InputsContainer>
            <Label fontWeight={600} htmlFor="zip">
                *Zip Code
            </Label>
            <Input
                type="number"
                id="zip"
                placeholder="Enter your billing address Zip Code"
                name="zip"
                value={props.formFields.zip}
                onChange={props.createChangeHandler("zip")}
            ></Input>
        </InputsContainer>
      <Button  style={{ zIndex: 1 }} height="40px" width="180px" onClick={validateFields(props.goToNextView)}>
        Next
      </Button>
      <Text important fontWeight={600} fontSize="14px">
        *Required Fields
      </Text>
        <FlexRow>
            <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
                <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
                    AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
                    <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventData.eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
                </span>
                </div>
            </div>
        </FlexRow>
    </>
  )
}

export default StepOne
