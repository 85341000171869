export const getConfig = () => {
  console.log(process.env)
  console.log(window.location.hostname)

  if (window.location.hostname === 'testapp.localhost') {
    console.log('Setting up for Local')
    return {
      apiUrl: 'https://testapi.auctionsnap.com:8443',
      version: 'Auction Snap 1.0.2',
    }
  }
  else if (window.location.hostname === 'test1.localhost') {
    console.log('Setting up for Local')
    return {
      apiUrl: 'https://testapi.auctionsnap.com:8443',
      version: 'Auction Snap 1.0.2',
    }
  }
  else if (window.location.hostname === 'staging1.localhost') {
    console.log('Setting up for Local')
    return {
      apiUrl: 'https://testapi.auctionsnap.com:8443',
      version: 'Auction Snap 1.0.2',
    }
  }
  else if (window.location.hostname === 'localhost') {
    console.log('Setting up for Local')
    return {
      apiUrl: 'https://testapi.auctionsnap.com:8443',
      version: 'Auction Snap 1.0.2',
    }
  }
  else if (window.location.hostname === 'staging1.auctionsnap.com' || window.location.hostname === 'staging2.auctionsnap.com') {
    console.log('Setting up for Staging')
    return {
      apiUrl: 'https://stagingapi.auctionsnap.com:8443',
      version: 'Auction Snap 1.0.2',
    }
  }
  else if (window.location.hostname === 'testapp.auctionsnap.com' || window.location.hostname === 'test1.auctionsnap.com') {
    console.log('Setting up for Dev')
    return {
      apiUrl: 'https://testapi.auctionsnap.com:8443',
      version: 'Auction Snap 1.0.2',
    }
  }
  else {
    console.log('Setting up for Live')
    return {
      apiUrl: 'https://api.auctionsnap.com:8443',
      version: 'AuctionSnap 1.0.2',
    }
  }
}
