import styled from "@emotion/styled"
import React from "react"
import Img from "./Img"
import LogoContainer from "./LogoContainer"
import LogoWrapper from "./LogoWrapper"
import waveBluePng from "../../assets/img/svg/wave-blue.png"
import Text from "../../pages/CommonStyles/Text"
import Menu from "../Menu/Index"
import {IonBadge, IonButton, IonIcon} from "@ionic/react";
import {cartOutline} from "ionicons/icons";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

type Props = {
  eventResponse: any
  isEventLoading: boolean
  showWelcomeMsg: boolean
  hideHamburgerMenu: boolean
  userInfo?: any
  page?: string
}

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 40px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

interface Params {
  eventcode: string
}

const EventWelcome: React.FC<Props> = (props: Props) => {
  const { eventcode } = useParams()

  const {
    eventResponse,
    isEventLoading,
    showWelcomeMsg,
    hideHamburgerMenu,
    userInfo,
    page
  } = props

  const cartState = useSelector((state: any) => state.cart)
  const navigate = useNavigate()

  const goToCart = () => {
    navigate("/" + eventcode + "/Cart")
  }

  return (
    <>
      {!isEventLoading && (
        <>
          <LogoWrapper>
            <LogoContainer>
              <Img
                alt="logo"
                src={eventResponse.logourl}
              />
            </LogoContainer>
          </LogoWrapper>
          {cartState?.auctionswon?.length > 0 &&
              <IonButton id="notification-button" ion-button fill="clear" onClick={goToCart}>
                <IonIcon
                    // slot="icon-only"
                    icon={cartOutline}
                    style={{width: 44, height: 44, color: "#D2222D"}}
                >
                </IonIcon>
                {cartState?.auctionswon?.length > 1 &&
                    <IonBadge id="notifications-badge" style={{
                      color: '#0a0a0a',
                      background: '#FFFFFF00',
                      position: 'absolute',
                      width: 22,
                      height: 18,
                      right: 10,
                      top: 12
                    }}>{cartState?.auctionswon?.length > 0 ? cartState?.auctionswon?.length : 0}</IonBadge>
                }
              </IonButton>
          }
          {!page && (
            <EventNameContainer>
              {eventResponse.eventname && (
                <Text
                  fontSize="35px"
                  fontWeight="bold"
                  textAlign="center"
                  padding="24px 0 16px 0"
                >
                  {eventResponse.eventname}
                </Text>
              )}
              {userInfo && (
                <Text fontSize="20px" textAlign="center">
                  Welcome, {userInfo.firstname} {userInfo.lastname}
                </Text>
              )}
              {showWelcomeMsg && (
                <Text fontSize="20px" textAlign="center" padding="24px">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventResponse.welcomemessage
                    }}
                  />
                </Text>
              )}
            </EventNameContainer>
          )}
          <WaveContainer></WaveContainer>
        </>
      )}
    </>
  )
}

export default EventWelcome
