import { ChangeEvent, useState } from "react"

const useFormFields = (initialValues: any = {}) => {
  const [formFields, setFormFields] = useState(initialValues)

  const createChangeHandler = (key: any) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.type === "checkbox") {
      const value = e.target.checked
      setFormFields((prev: any) => ({ ...prev, [key]: value }))
    } else {
      const value = e.target.value
      setFormFields((prev: any) => ({ ...prev, [key]: value }))
    }
  }
  return { formFields, createChangeHandler }
}

export default useFormFields
