import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useLocation, useParams} from "react-router-dom";

import styled from "@emotion/styled";

import NavMenuContainer from "./NavMenuContainer";
import NavMenuItem from "./NavMenuItem";
import StyledLink from "./StyledLink";
import ButtonItem from "../../pages/CommonStyles/ButtonItem";
import {updateStateOnLogout} from "../../redux/slices/userSlice";
import {disconnected} from "../../redux/slices/signalrSlice";


const Slice = styled.div`
  width: 30px;
  height: 5px;
  background-color: #d2222d;
  margin: 6px 0;
`;
const HamburgerContainer = styled.div`
  width: 40px;
  padding: 0 1rem;
`;
const MenuWrapper = styled.div`
  z-index: 100;
`;

type Props = {
  menuOpen: boolean
  setMenuOpen: any
}

interface Params {
  eventcode: string
}

const Menu: React.FC<Props> = ({ menuOpen, setMenuOpen }) => {
  const { eventcode } = useParams()
  // const [menuOpen, setMenuOpen] = useState<boolean>(false);
  // const eventcode = useSelector((state: any) => state.event.eventcode);
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  const accountStatus = useSelector((state: any) => state.user.accountstatus);
  const location = useLocation();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (menuOpen) setMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const logUserOut = () => {
    dispatch(updateStateOnLogout())
    const token = localStorage.getItem("token")
    localStorage.removeItem("token")
    dispatch(disconnected())
    dispatch({
      type: "DISCONNECT_SIGNALR"
    })

    navigate('/' + eventcode)
  }

  return (
    <>
      <MenuWrapper>
        <NavMenuContainer open={menuOpen}>
          <NavMenuItem>
            <StyledLink route={'/' + eventcode + '/welcome'} routeName="Home"></StyledLink>
          </NavMenuItem>
          <NavMenuItem>
            <StyledLink
              route={'/' + eventcode + '/auction-items'}
              routeName="Auction Items"
            ></StyledLink>
          </NavMenuItem>
          {accountStatus !== 'guest' &&
              <>
                <NavMenuItem>
                  <StyledLink route={'/' + eventcode + '/cart'} routeName="My Cart"></StyledLink>
                </NavMenuItem>
                <NavMenuItem>
                  <StyledLink
                      route={'/' + eventcode + '/my-orders'}
                      routeName="Order History"
                  ></StyledLink>
                </NavMenuItem>
                <NavMenuItem>
                  <StyledLink
                      route={'/' + eventcode + '/my-account'}
                      routeName="My Account"
                  ></StyledLink>
                </NavMenuItem>
                <NavMenuItem>
                  <StyledLink
                      route={'/' + eventcode}
                      routeName="My Account"
                      logout
                  ></StyledLink>
                </NavMenuItem>
              </>
          }

{/*          <NavMenuItem>
            <StyledLink route={`/debug`} routeName="Debug"></StyledLink>
          </NavMenuItem>*/}
        </NavMenuContainer>
      </MenuWrapper>
    </>
  );
};

export default Menu;
