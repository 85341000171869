import {getEndPoint, SUBMIT_MAX_BID} from "./enpoints"
import { client } from "./fetchData"

type maxItemDetails = {
  itemid: number
  maxbid?: number
  increment?: number
}

export const submitMaxBid = (itemDetails: maxItemDetails) => {
  const token = localStorage.getItem("token")
  console.log(itemDetails)
  return client.post(getEndPoint(SUBMIT_MAX_BID), itemDetails, {
    headers: {
      Authorization: "Bearer " + token
    }
  })
}

