import React, { useEffect }  from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import jwt_decode from "jwt-decode";
import { updateStateOnLogout } from "../redux/slices/userSlice";


interface Params {
    eventcode: string
}

export const useAuthRefresh = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { eventcode } = useParams()

    const logout = () => {
        dispatch(updateStateOnLogout())
        const token = localStorage.getItem("token")
        localStorage.removeItem("token")
        dispatch({
            type: "DISCONNECT_SIGNALR"
        })

        const decoded: any = jwt_decode(token)
        if (decoded.eventcode) {
            navigate('/' + decoded.eventcode)
        }
        else {
            console.log('push login AUTH')
            navigate('/' + eventcode + '/login/')
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("token")

        if (token) {
            var decoded: any = jwt_decode(token)
            var current_time = Date.now() / 1000;
            if (decoded.exp < current_time) {
                console.log('Token has expired!!!')
                logout()
            }
            else {
                console.log('not expired')
            }
        }
        else
        {
            console.log('no token - not-found (authenicationHook)')
            console.log('redirect - /' + eventcode)
            navigate('/' + eventcode)
        }
    })
    return;
}


