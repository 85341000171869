import {
  JsonHubProtocol,
  HubConnectionBuilder,
  LogLevel,
  HubConnection, HubConnectionState
} from "@microsoft/signalr"
import { Store } from "@reduxjs/toolkit"
import { Store as notificationStore } from 'react-notifications-component';
import { updateEventData } from "../slices/messageSlice"
import { connected, disconnected, reconnecting } from "../slices/signalrSlice"
import {updateStateOnLogout, updateUserInfo} from "../slices/userSlice"
import {updateItemOnBid, itemUpdate, fetchAuctionItemsData} from "../slices/auctionItemsSlice"
import {fetchAuctionItemDetailsData, updateItemDetailsOnBid} from "../slices/auctionItemDetailsSlice"
import {setCartReset, setEventClosing, setEventReset, setForceLogout} from "../slices/eventSlice";
import {useSelector} from "react-redux";
import {fetchCartData} from "../slices/cartSlice";
import {CART_ENDPOINT, getEndPoint} from "../../util/api/enpoints";

let connection: HubConnection

const configureConnection = (action: any) => {
  const token = action.payload.token ? action.payload.token : null

  connection =  new HubConnectionBuilder()
    .withUrl(action.payload.url, { accessTokenFactory: () => { return token as string } })
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.Debug)
    .build()
}

const updateStoreOnResponse = (store: Store, msg: any) => {
  const parsedData = JSON.parse(msg.jsonString)
  console.log(parsedData)
  const state = store.getState()
  const eventcode = state.event?.eventData?.eventcode
  const auctioncode = state.event?.eventData?.auctioncode
  const eventData = state.event?.eventData
  const userId = state.user?.userid
  const email = state.user?.email
  const itemid = state.auctionItemDetails?.itemid

  switch (parsedData.type) {
    case "eventrefresh":
      if (eventData.eventcode === parsedData.eventcode && eventData.auctioncode === parsedData.auctioncode || parsedData.email === email) {
        store.dispatch(setEventReset(true))
      }
      break
    case "cartrefresh":
      if (userId === parsedData.userid) {
        store.dispatch(setCartReset(true))
      }
      break
    case "eventcode":
      store.dispatch(updateEventData(parsedData))
      break
    case "emailValidation":
      if (parsedData.status === "valid") {
        store.dispatch(
          updateUserInfo({
            accountstatus: "verified"
          })
        )
      }
      break
    case "forcelogout":
      console.log('forcelogout')
      store.dispatch(setForceLogout(true))
      break
    case "itembid":
      if (
        eventcode.toLowerCase() === parsedData.eventcode.toLowerCase() &&
        auctioncode.toLowerCase() === parsedData.auctioncode.toLowerCase() &&
        userId === parsedData.userid
      ) {
        store.dispatch(
          updateItemOnBid({
            ...parsedData
          })
        )
        store.dispatch(
          updateItemDetailsOnBid({
            ...parsedData
          })
        )
      }
      break
    case "eventclosing":
      if (eventData.eventcode === parsedData.eventcode && eventData.auctioncode === parsedData.auctioncode) {
        store.dispatch(setEventClosing(true))
      }
      break
    case "itemupdate":
      if (
          eventcode.toLowerCase() === parsedData.eventcode.toLowerCase() &&
          auctioncode.toLowerCase() === parsedData.auctioncode.toLowerCase()
      ) {
          store.dispatch(
              itemUpdate({
                ...parsedData
              })
          )
        if (parsedData.itemid === itemid) {
          store.dispatch(
              updateItemDetailsOnBid({
                ...parsedData
              })
          )
        }
      }
      break
    case "updateUser":
      store.dispatch(
          updateUserInfo({
            ...parsedData
          })
      )
          break
    case "itemmessage":
      if (
          eventcode.toLowerCase() === parsedData.eventcode.toLowerCase() &&
          auctioncode.toLowerCase() === parsedData.auctioncode.toLowerCase() &&
          userId === parsedData.userid
      ) {
        if (parsedData.itemid === itemid) {
          store.dispatch(
              updateItemOnBid({
                ...parsedData
              })
          )
          store.dispatch(
              updateItemDetailsOnBid({
                ...parsedData
              })
          )
        }
        if (parsedData && parsedData.message.length > 0) {
            notificationStore.addNotification({
              title:  parsedData.message,
              // message: parsedData.message,
              type: "info",
              insert: "bottom",
              container: "bottom-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              width: 400,
              touchSlidingExit: {
                swipe: {
                  duration: 400,
                  timingFunction: 'ease-out',
                  delay: 0,
                },
                fade: {
                  duration: 400,
                  timingFunction: 'ease-out',
                  delay: 0
                }
              },
              dismiss: {
                duration: 0,
                showIcon: true,
                click: true,
                touch: true
              }
            });
        }
      }
      break
    case "submitbid":
      if (
          eventcode.toLowerCase() === parsedData.eventcode.toLowerCase() &&
          auctioncode.toLowerCase() === parsedData.auctioncode.toLowerCase() &&
          userId === parsedData.userid
      ) {
        store.dispatch(
            updateItemOnBid({
              ...parsedData
            })
        )
        store.dispatch(
            updateItemDetailsOnBid({
              ...parsedData
            })
        )
      }
      break
    case "notification":
      if (
          eventcode.toLowerCase() === parsedData.eventcode.toLowerCase() &&
          auctioncode.toLowerCase() === parsedData.auctioncode.toLowerCase()
      ) {
        // store.dispatch(showNotification({message:parsedData.message, isOpen: true}))
        console.log(parsedData)
        notificationStore.addNotification({
          title:  parsedData.message,
          // message: parsedData.message,
          type: "info",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          width: 400,
          touchSlidingExit: {
            swipe: {
              duration: 400,
              timingFunction: 'ease-out',
              delay: 0,
            },
            fade: {
              duration: 400,
              timingFunction: 'ease-out',
              delay: 0
            }
          },
          dismiss: {
            duration: 0,
            showIcon: true,
            click: true,
            touch: true
          }
        });
      }

      break
    default:
      console.log("Response does not contain appropriate type !!!")
  }
}

const startConnection = async (store: Store, action) => {
    try {

        connection.on("ReceiveJSON", (msg: any) => {
            updateStoreOnResponse(store, msg)
        })

        // connection.onclose(async () => {
        //   console.log('onclose')
        //   await startConnection(store, action);
        // });

        await connection.start()
        if (connection.state === HubConnectionState.Connected) {
          store.dispatch(connected())
        }
    }
    catch (err) {
      setTimeout(startConnection.bind(store, action), 5000);
    }
}

const createSignalrMiddleware = () => {
  return (store: any) => (next: any) => async (action: any) => {
    switch (action.type) {
      case "CONNECT_SIGNALR":
        configureConnection(action)
        connection.onclose(() => {
          store.dispatch(disconnected())
        })
        setTimeout(() => {}, 5000)
        break
      case "SEND_MSG":
        let state = store.getState()
        if (state.signalr.connected) {
          try {
            const message = JSON.stringify(action.payload)
            await connection?.send("SendJSON", { jsonString: message })
            next(action)
          } catch (e) {
            console.log(e)
          }
        }
        break
      case "TOKEN_CONNECT":
        if (connection?.state === HubConnectionState.Connected) {
          connection?.stop()
        }
        configureConnection(action)
        startConnection(store, action)
        break
      /** do we stop signalr on logiut and reconnect ? */
      case "DISCONNECT_SIGNALR":
        store.dispatch(disconnected())
        if (connection) {
          connection.stop()
        }
        break
      default:
        return next(action)
    }
  }
}

export default createSignalrMiddleware()
