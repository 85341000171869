import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export interface SignalRItem {
    type: string;
    itemid: number;
    eventcode: string;
    auctioncode: string;
    bidamount: number;
    statusConnected: boolean;
    message: string;
    jsonMessage: string;
}

type SignalRItemState = {
    signalRItems: SignalRItem[]
}

const initialState: SignalRItemState = {
    signalRItems: []
}

export const slice = createSlice({
    name: "SignalRItems",
    initialState,
    reducers: {
        resetSignalRItems: (state, action) => {
            state.signalRItems = []
        },
        updateSignalRItems: (state, action) => {
            const newItems = state.signalRItems.map((item: SignalRItem) => {
                return item
            })
            state.signalRItems = [...newItems, action.payload]
        }
    }
})

export const { resetSignalRItems, updateSignalRItems } = slice.actions
export default slice.reducer