import { useSelector } from "react-redux"

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { client } from "../../util/api/fetchData"
import { AppState } from "../store"
import auctionItemDetailsSlice from "./auctionItemDetailsSlice";

export interface AuctionItem {
  rownumber: number
  itemid: number
  name: string
  description: string
  longdescription: string
  startingbid: number
  tags: string
  numberofbids: number
  lastbid: number
  open: string
  closing: string
  opencd: string
  opencst: string
  closingcd: string
  closingcst: string
  watchlist: boolean
  openforbid: boolean
  buyitnowavailable: boolean
  buyitnowonly: boolean
  allowautobid: boolean
  buyitnowprice: number
  bidstatus: string
  accountbiddingon: boolean
  currentlywinningbid: boolean
  itemisliveonly: boolean
  onlineauction: boolean
  imageurl: string
  unlimitedquantity: boolean
  maxpurchasequantity: number
  accountisautobid: boolean
  accountautobidamount: number
  accountautobidincrement: number
}

type AuctionItemsState = {
  message: string
  auctionitems: AuctionItem[]
  tags: any
  status: "idle" | "loading" | "succeeded" | "failed"
  error: any
  sortValue: string
}

export const sortOptions = {
  FEATURED_ITEMS: "Featured Items",
  ENDING_SOON: "Ending Soonest",
  ALPHA_ASC: "A to Z",
  ALPHA_DESC: "Z to A",
  PRICE_ASC: "Price Low to High",
  PRICE_DESC: "Price High to Low",
};

const initialState: AuctionItemsState = {
  message: "",
  auctionitems: [],
  tags: [],
  status: "idle",
  error: null,
  sortValue: sortOptions.FEATURED_ITEMS
}

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

const handleToggleSuccess = (state: AuctionItemsState, action: any) => {
  const itemid = action?.meta?.arg?.itemid
  const updatedItem = state.auctionitems?.find((item) => item.itemid === itemid)
  if (updatedItem) {
    updatedItem.watchlist = !updatedItem?.watchlist
  }
}

export const fetchAuctionItemsData = createAsyncThunk(
  "auctionitems/fetchAuctionItemsData",
  async (url: string) => {
    const token = localStorage.getItem("token")
    const response = await client.post(
      url,
      {
        includethumbnail: true
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    )
    const parsedData = JSON.parse(response.jsonString)
    return parsedData
  }
)

export const toggleWatchlistItem = createAsyncThunk(
  "auctionitems/toggleWatchListItem",
  async ({ url, itemid }: { url: string; itemid: number }) => {
    const token = localStorage.getItem("token")
    const response = await client.post(
      url,
      {
        itemid
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    )
    const parsedData: {
      status: "success" | "failure"
    } = JSON.parse(response.jsonString)
    return parsedData
  }
)

export const slice = createSlice({
  name: "auctionitems",
  initialState,
  reducers: {
    setSortValue: (state, {payload}: PayloadAction<string>) => {
      state.sortValue = payload
    },
    updateAuctionItems: (state) => {
      const newItems = state.auctionitems.map((item: any) => {
        return {
          ...item

        }
      })
      state.auctionitems = newItems
    },
    updateItemOnBid: (state, action) => {
      const newItems = state.auctionitems.map((item: AuctionItem) => {
        if (item.itemid === action.payload.itemid) {
          return {
            ...item,
            lastbid: action.payload.bidamount,
            quantity: action.payload.quantity,
            quantityavailable: action.payload.quantity,
            numberofbids: action.payload.numberOfbids,
            currentlywinningbid: action.payload.currentlywinningbid,
            accountbiddingon: action.payload.accountbiddingon
          }
        } else {
          return item
        }
      })
      state.auctionitems = newItems
    },
    updateItemMaxBid: (state, action) => {
      const newItems = state.auctionitems.map((item: AuctionItem) => {
        if (item.itemid === action.payload.itemid) {
          return {
            ...item,
            accountisautobid: action.payload.accountisautobid,
          }
        } else {
          return item
        }
      })
      state.auctionitems = newItems
    },
    updateAutoBid: (state, action) => {
      const newItems = state.auctionitems.map((item: AuctionItem) => {
        if (item.itemid === action.payload.itemid) {
          const newItem = {
            ...item,
            accountisautobid: action.payload.accountisautobid,
            accountautobidamount: action.payload.accountautobidamount,
            accountautobidincrement: action.payload.accountautobidincrement,
          }
          return newItem
        } else {
          return item
        }
      })
      state.auctionitems = newItems
    },
    itemUpdate: (state, action) => {
      const newItems = state.auctionitems.map((item: AuctionItem) => {
        if (item.itemid === action.payload.itemid) {
          return {
            ...item,
            lastbid: action.payload.bidamount,
            quantity: action.payload.quantity,
            quantityavailable: action.payload.quantity,
            numberofbids: action.payload.numberOfbids
          }
        } else {
          return item
        }
      })
      state.auctionitems = newItems
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      "auctionitems/fetchAuctionItemsData/pending",
      (state, action) => {
        state.status = "loading"
      }
    )
    builder.addCase(
      "auctionitems/fetchAuctionItemsData/fulfilled",
      (state, action: any) => {
        Object.keys(state).forEach((key) => {
          if (key in action.payload) {
            if (hasKey(state, key)) {
              let value = action.payload[key]
              state[key] = value
            }
          }
        })
        state.auctionitems.forEach((auctionitem: any, index: any) => {
          auctionitem.onlineauction = !auctionitem.itemisliveonly
          if (auctionitem.tags) {
            auctionitem.tags.split(",").forEach((oneTag: any, indexTag: any) => {
              if (state.tags.indexOf(oneTag.trim()) === -1)
                state.tags.push(oneTag.trim())
            })
          }
        })
        state.status = "succeeded"
      }
    )
    builder.addCase(
      "auctionitems/fetchAuctionItemsData/rejected",
      (state, action: any) => {
        state.status = "failed"
        state.error = action.error.message
      }
    )
    builder.addCase(
      "auctionitems/toggleWatchListItem/fulfilled",
      handleToggleSuccess
    )
  }
})

export const { updateAuctionItems, updateItemOnBid, itemUpdate, setSortValue, updateItemMaxBid, updateAutoBid} = slice.actions
export default slice.reducer

export const useAuctionItemsState = () => {
  return useSelector((state: AppState) => state.auctionItems)
}

export const useSortValue = () => {
  return useSelector((state: AppState) => state.auctionItems.sortValue)
}
