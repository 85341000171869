import styled from "@emotion/styled"

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 10px;
  padding: 10px;
`

export default InputsContainer
