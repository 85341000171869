import React, { useEffect } from "react"
import {useDispatch, useSelector} from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"
import {updateStateOnLogout, updateUserInfo} from "../../redux/slices/userSlice";
import {verifyAccountPost} from "../../util/api/verifyAccount";
import styled from "@emotion/styled";
import store from "../../redux/store";
import {fetchEventData, setAuctionCode, setEventCode} from "../../redux/slices/eventSlice";
import {getEndPoint} from "../../util/api/enpoints";
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import jwt_decode from "jwt-decode";
import {setPrivacyEventCode} from "../../redux/slices/privacyPolicySlice";

const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

interface Params {
    eventcode: string
    id: string
}

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`

const LinkContainer = styled.div`
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
  `


const VerifyAccount: React.FC = () => {
    const event = useSelector((state: any) => state.event)
    const user = useSelector((state: any) => state.user)
    const { eventcode, id } = useParams()

    const checkEmailVerify = async (id: string) => {
        const response = await verifyAccountPost(id)
        const parsedData = JSON.parse(response.jsonString)
        // const dispatch = useDispatch()
        var auctioncode = ''
        var eventCode = ''

        if (parsedData.status === 'success') {
            if (localStorage.getItem("token")) {
                localStorage.removeItem("token")
            }

            const decoded: any = jwt_decode(parsedData.token)
            var current_time = Date.now() / 1000;
            if (decoded.exp < current_time) {
                // expired
                navigate('/' + eventcode + '/login/')
            }
            else
            {
                localStorage.setItem("token", parsedData.token)
                if (decoded.eventcode) {
                    eventCode = decoded.eventcode
                }
                if (decoded.auctioncode) {
                    auctioncode = decoded.auctioncode
                }
                console.log(decoded)
                dispatch(setEventCode(eventCode))
                dispatch(setAuctionCode(auctioncode))
                dispatch(setPrivacyEventCode(eventCode))

                store.dispatch(
                    fetchEventData(getEndPoint("EVENT_ENDPOINT", [eventCode]))
                )

                store.dispatch(
                    updateUserInfo({
                        accountstatus: "verified",
                        userid: parsedData.userid,
                        authorizebidder: parsedData.authorizebidder
                    })
                )
            }
        }
    }

    useEffect(() => {
        dispatch(
            fetchEventData(getEndPoint("EVENT_ENDPOINT", [id]))
        )
    }, [id])

    checkEmailVerify(id)

    const elementScroll = document.getElementById("scrollBody")
    if (elementScroll) {
        elementScroll.scroll(0,0)
    }
    
    // const eventcode = useSelector((state: any) => state.event.eventcode)
    // const auctioncode = useSelector((state: any) => state.event.auctioncode)
    const userAccountStatus = useSelector(
    (state: any) => state.user.accountstatus
    )
    const userAccountSetupComplete = useSelector(
    (state: any) => state.user.accountSetupComplete
    )

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const logUserOutClick = () => {
        dispatch(updateStateOnLogout())
        localStorage.removeItem("token")
        dispatch({
            type: "DISCONNECT_SIGNALR"
        })
    }
    const logUserOut = () => {
        return '/' + eventcode
    }

    useEffect(() => {
        if (userAccountStatus === "verified") {
              if (userAccountSetupComplete) {
                /** we'll go to welcom/auction page */
              } else {
                navigate("/" + eventcode + '/setup-account')
              }
        }
    }, [userAccountStatus, userAccountSetupComplete])

  return (
    <PageBodyContainer justifyContent="space-evenly">
        <EventNameContainer>
            {event.eventname && (
                <Text
                    fontSize="35px"
                    fontWeight="bold"
                    textAlign="center"
                    padding="24px 0 16px 0"
                >
                    {event.eventname}
                </Text>
            )}

            {/*<Text fontSize="20px" textAlign="center" padding="24px">*/}
            {/*    <div*/}
            {/*        dangerouslySetInnerHTML={{*/}
            {/*            __html: event.homemessage*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Text>*/}
        </EventNameContainer>
        <WaveContainer></WaveContainer>
        <Text important padding="14px" fontWeight={600} textAlign="center">
            We just emailed {user.email}.
        </Text>
        <Text important padding="24px" fontSize="16px" fontWeight={600} textAlign="center">
            Click the "Verify Email Address" button in the email to continue.
        </Text>

        <LinkContainer>
            <Text style={{ zIndex: 1 }} important fontSize="15px" padding="14px" decoration="underline" >
                <Link style={{ textDecoration: 'none' }} onClick={logUserOutClick} to={logUserOut()} >Typo in your email address? Return to Login?</Link>
            </Text>
        </LinkContainer>
    </PageBodyContainer>
  )
}

export default VerifyAccount
